<script setup lang="ts">
const props = defineProps<{
  isAd?: boolean
  secondaryIcon?: boolean
}>()

const hideButtonOnBridge = brand.id !== 'bridge'
</script>

<template>
  <div class="bg-[#305ab5] z-2">
    <slot name="banner" />
    <div class="container flex items-center py-2 relative">
      <div class="absolute top-2 right-1 z-10 flex flex-col gap-4">
        <ClickToCall
          v-if="hideButtonOnBridge"
          v-slot="{ handleClick }"
          :props="{ ctcLocation: 'header' }"
        >
          <a
            :href="phone.uri"
            @click="handleClick"
            class="bg-[#FF5A5F] rounded-xl min-w-22 h-8 w-8 flex items-center gap-2 justify-center text-center relative text-white"
          >
            <Icon i-heroicons-solid:phone class="w-4 h-4 animate-shake" />
          </a>
        </ClickToCall>

        <span
          v-if="isAd"
          class="text-xs flex-grow-0 w-7 text-gray-400 border border-gray-400 p-1 rounded bg-white shadow-md text-center self-end"
        >
          Ad
        </span>
      </div>

      <RouterLink to="/" class="mx-auto">
        <div v-if="brand.id === 'bh'">
          <img
            v-if="!secondaryIcon"
            src="../assets/logo-bh-alt.png"
            class="brightness-0 invert-100 opacity-90 max-h-11 w-auto"
            width="694"
            height="234"
          />
          <img
            v-else
            src="../assets/plus_icon.png"
            class="max-h-11 w-auto"
            width="240"
            height="240"
          />
        </div>
        <Logo v-else class="brightness-0 invert-100 opacity-90 !max-h-8" />
      </RouterLink>
    </div>
  </div>
</template>
