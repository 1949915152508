<script setup lang="ts">
const { completed, total } = inject<any>('form')

const currentN = computed(() => {
  return completed.value + 1
})
</script>

<template>
  <div class="flex justify-center">
    <div
      v-for="n in total"
      :key="n"
      :class="[n !== total && 'pr-4 sm:pr-12', 'relative']"
    >
      <div class="absolute inset-0 flex items-center">
        <div
          :class="[n < currentN ? 'bg-blue' : 'bg-gray-200', 'h-0.5 w-full']"
        />
      </div>

      <div
        :class="[
          n < currentN && 'bg-blue',
          n === currentN && 'border-2 border-blue bg-white',
          n > currentN && 'border-2 border-gray-200 bg-white',
          'relative flex h-6 w-6 items-center justify-center rounded-full',
        ]"
      >
        <Icon
          v-if="n < currentN"
          i-heroicons-solid:check
          class="w-4 h-4 text-white"
        />
        <div v-if="n === currentN" class="relative flex w-2 h-2">
          <div
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue opacity-75"
          >
          </div>
          <div class="relative inline-flex rounded-full h-2 w-2 bg-blue"></div>
        </div>
      </div>
    </div>
  </div>
</template>
