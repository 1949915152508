interface Form {
  [key: string]: any
}

interface Forms {
  [key: string]: Form | undefined
}

export const formsRef = useLocalStorage<Forms>('forms', {})

export const forms = toReactive(formsRef)

export interface Step {
  id: string
  skip: boolean
  completed: boolean
  available: boolean
  index: number
}
