export type CountyResponse = {
  name: string
  fips: string
  state: string
}

export const getCounty = async (
  zipCode: string
): Promise<CountyResponse | null> => {
  if (!zipCode) return null

  let url =
    import.meta.env.VITE_WEBSITE_API_URL +
    `/location/county?zip_code=${zipCode}`

  const { data } = await axios.get<CountyResponse>(url)
  return data
}
