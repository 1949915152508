import { CarrierOTCSummaryData } from './carriers'
import { PlanRowView } from './plan'

export const getReviewCardData = async (carrier: string) => {
  const zipCode = ip.lookup?.postalCode ?? query.zip
  const stateCode = ip.lookup?.stateCode ?? query.stateCode
  let planRowViewArray: PlanRowView[] = []
  let carrierOtcSummary: CarrierOTCSummaryData | null = null

  if (zipCode) {
    const data = await getCarriersOTCSummaries({
      zipCode,
      carriers: [carrier],
    })

    const planData = await getPlans({
      zipCode,
      carrier_groups: [carrier],
      amount: 100,
      starting_from: 0,
    })
    if (planData) {
      planRowViewArray = planData
    }
    if (data) {
      carrierOtcSummary = data && data.length ? data[0] : null
    }
  } else if (stateCode) {
    const data = await getCarriersOTCSummaries({
      stateCode,
      carriers: [carrier],
    })

    const planData = await getPlans({
      stateCode,
      carrier_groups: [carrier],
      amount: 100,
      starting_from: 0,
    })
    if (planData) {
      planRowViewArray = planData
    }
    if (data) {
      carrierOtcSummary = data && data.length ? data[0] : null
    }
  }

  return {
    carrierOtcSummary,
    planRowViewArray,
  }
}
