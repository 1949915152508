<script setup lang="ts">
const props = defineProps<{
  rating: number
  maxRange: number
  backgroundColor?: string
  maxStars?: number
}>()

const defaultMaxStars = 5
const defaultBgColor = 'bg-yellow-400'
const bgColor = props.backgroundColor || defaultBgColor
const maxStars = props.maxStars || defaultMaxStars

const rating =
  props.maxRange <= maxStars
    ? props.rating
    : parseFloat(parseFloat(`${props.rating / 2}`).toFixed(1))

const isFullStar = (index: number, rating: number): boolean => {
  const isFull = index <= Math.floor(rating)
  return isFull
}

const isAlmostFullStar = (index: number, rating: number): boolean => {
  const fraction = rating % 1
  const isAlmost =
    index === Math.floor(rating) + 1 && fraction >= 0.6 && fraction < 1
  return isAlmost
}
</script>

<template>
  <template v-for="index in 5" :key="index">
    <Icon
      v-if="isFullStar(index, rating) || isAlmostFullStar(index, rating)"
      i-heroicons-solid:star
      :class="[bgColor, 'h-4 w-4']"
    />
    <Icon v-else i-heroicons-outline:star class="bg-gray-400 h-3.5 w-3.5" />
  </template>
</template>
