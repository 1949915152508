<script setup lang="ts">
const start = Date.now()
const now = useNow()
const router = useRouter()
const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 0)),
  'mm:ss'
)
const healthInsurancePlan = inject('healthInsurancePlan') as number
const isPaidUser = inject('isPaidUser') as boolean

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd && state.top5Otc) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
      top5Otc: `$${state.top5Otc}`,
      medicaid: state.medicaid,
    }
  }
})

watch(timer, () => {
  if (timer.value === '00:00') {
    router.push('/aca-eligibility-quiz-d3k/medicaid')
  }
})
</script>

<template>
  <div class="text-3xl text-green-600 font-semibold"> Congratulations! </div>

  <div class="text-lg font-medium">
    <template v-if="!isMobile || !isPaidUser || !state">
      We were able to find new plans that you may qualify for.
      <span class="bg-yellow-400 whitespace-nowrap">Click below</span> to review
      them with a licensed insurance agent. We're standing by to help!
    </template>
    <template v-else>
      <template v-if="healthInsurancePlan === 1600">
        We found Health Insurance plans with
        <span class="bg-yellow-400"> $1600 in subsidies! </span>. Use benefits
        on plan approved items like:
      </template>
      <template v-else>
        Residents of {{ state.name }} Who are NOT on
        {{ state?.medicaid ?? 'Medicaid' }} May Claim their
        <span class="bg-yellow-400">
          ${{ healthInsurancePlan }} Health Insurance today</span
        >, thanks to the Affordable Care Act.
      </template>
    </template>
  </div>

  <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded p-4 space-y-2">
    <div
      class="text-lg font-semibold text-center"
      v-if="healthInsurancePlan === 1600"
    >
      Call Now to Claim Your ${{ healthInsurancePlan }} Subsidy
    </div>
    <div class="text-lg font-semibold text-center" v-else>
      Claim Your ${{ healthInsurancePlan }} Health Insurance
    </div>

    <div class="flex items-center justify-center gap-2">
      <div class="relative flex w-2 h-2">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-2 w-2 bg-green-500">
        </div>
      </div>
      <div> Live agent connected & waiting </div>
    </div>

    <ClickToCall text="Call Now" :props="{ ctcLocation: 'last-step' }" />

    <div class="text-center">
      Due to high call volume we can only <br />
      reserve your spot for <span class="font-semibold">2 minutes</span>. <br />
      <span class="text-2xl font-medium text-red"> {{ timer }} </span>
    </div>
  </div>
</template>
