<script setup lang="ts">
const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red-500 text-center">
    Looks like you may qualify!
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Call the Health Insurance Helpline now.
  </div>

  <ClickToCall />

  <div class="text-lg sm:text-xl font-medium text-center">
    This pre-approval expires in <span class="text-red"> {{ timer }} </span>
    <br />
    Act quickly!
  </div>
</template>
