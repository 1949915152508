import { Product } from '~/modules/product'

export interface PageOptions {
  name: string
  category: string
  product?: Product
  [key: string]: any
}

interface Analytics {
  page: (options: PageOptions) => void
  track: (event: string, props?: object) => void
}

const defaultProps = () => {
  const afid =
    typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
      ? phoneConfig.value.afid
      : undefined

  const gaCookie = brand.measurementId
    ? cookies[`_ga_${brand.measurementId}`] || null
    : null

  const gaSessionId =
    gaCookie && /^GS1\.1\.\d+\./.test(gaCookie)
      ? gaCookie.replace('GS1.1.', '').split('.')[0]
      : null

  return {
    afid: getSessionEnhanced().query.afid || afid,
    website: brand.id,
    sessionId: getSession().id,
    isPaidUser: getSessionEnhanced().isPaidUser,
    gaClientId: ga.clientId,
    gaSessionId,
    fbp: cookies._fbp || null,
    userAgent: userAgent.value,
    benefits: routeBenefits.value,
    language: language.value,
    smid: smid.value,
    botDetectionResult: botDetectionResult.value,
    fbclid: query.fbclid,
    gclid: query.gclid,
    tblci: query.tblci,
    token: query.token,
    utm_source: query.utm_source,
    utm_medium: query.utm_medium,
    utm_campaign: query.utm_campaign,
    utm_content: query.utm_content,
    utm_term: query.utm_term,
    session: {
      fbclid: getSessionEnhanced().query.fbclid,
      gclid: getSessionEnhanced().query.gclid,
      tblci: getSessionEnhanced().query.tblci,
      token: getSessionEnhanced().query.token,
      utm_source: getSessionEnhanced().query.utm_source,
      utm_medium: getSessionEnhanced().query.utm_medium,
      utm_campaign: getSessionEnhanced().query.utm_campaign,
      utm_content: getSessionEnhanced().query.utm_content,
      utm_term: getSessionEnhanced().query.utm_term,
    },
    ipLookup:
      ip.lookup &&
      _.pick(ip.lookup, [
        'cityName',
        'stateCode',
        'postalCode',
        'value',
        'isp',
      ]),
  }
}

const defaultPropsPage = () => {
  return {
    url: location.href,
    path: location.pathname,
    title: document.title,
    search: location.search,
    referrer: document.referrer,
  }
}

export const analytics: Analytics = {
  track: (event, props) => {
    if (!import.meta.env.SSR) {
      dataLayer.push({
        ...defaultProps(),
        ...props,
        event,
        segmentAnonymousId: segment.anonymousId,
      })
      axios
        .post(
          import.meta.env.VITE_EH_INTERNAL_API_URL +
            '/segment/archive/web-events',
          {
            type: 'track',
            timestamp: new Date().toISOString(),
            sessionId: getSession().id,
            anonymousId: segment.anonymousId,
            properties: {
              ...defaultProps(),
              ...props,
              website: brand.id,
              referrer: document.referrer,
              url: {
                full: window.location.href,
                path: window.location.pathname,
                query,
              },
            },
            event,
          },
          {
            headers: {
              'x-api-key': import.meta.env.VITE_EH_INTERNAL_API_KEY,
            },
          }
        )
        .then(() => {})
        .catch(() => {})
      extendSession()
    }
  },
  page: ({ name, category, product = null, ...props }) => {
    if (!import.meta.env.SSR) {
      dataLayer.push({
        ...defaultProps(),
        ...defaultPropsPage(),
        ...props,
        name,
        category,
        product,
        event: 'Loaded a Page',
        segmentAnonymousId: segment.anonymousId,
      })
      axios
        .post(
          import.meta.env.VITE_EH_INTERNAL_API_URL +
            '/segment/archive/web-events',
          {
            type: 'page',
            timestamp: new Date().toISOString(),
            sessionId: getSession().id,
            anonymousId: segment.anonymousId,
            properties: {
              ...defaultProps(),
              ...props,
              product,
              website: brand.id,
              referrer: document.referrer,
              url: {
                full: window.location.href,
                path: window.location.pathname,
                query,
              },
            },
            name,
            category,
          },
          {
            headers: {
              'x-api-key': import.meta.env.VITE_EH_INTERNAL_API_KEY,
            },
          }
        )
        .then(() => {})
        .catch(() => {})
      dataLayer.push({ event: 'pageView' })
      extendSession()
    }
  },
}
