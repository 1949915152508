<script setup lang="ts">
const isPaidUser = ref(false)
const router = useRouter()

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
  }
})

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  if (!isPaidUser.value) {
    router.push('/go-4-benefits')
  }
})

const shrinkClass = ref('')
const onTypewriterDone = () => {
  shrinkClass.value = ' !w-[190px]'
}
</script>

<template>
  <Layout class="text-cyan-900 flex gap-6 flex-col font-dm">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="container flex flex-col gap-6">
      <h1 class="text-center text-3xl md:text-5xl font-bold mt-4">
        <span class="text-green-600">A Grocery Allowance Card</span>
        Benefit May Be Available To You If You’re On Medicare & Medicaid.
      </h1>
      <div class="text-center mt-4 text-xl md:text-2xl">
        You could be eligible for a Dual-Eligible Special Needs Medicare
        Advantage Plan with a Grocery Allowance Card benefit!
      </div>
      <div class="flex gap-12 flex-col items-center mt-4">
        <AllowanceCard
          :target-value="stateConfig?.minOtcLtd"
          :class="['rotate-6 custom-transition w-[310px]', shrinkClass]"
          @done="onTypewriterDone"
        />
        <div
          class="mt-4 bg-white rounded-lg shadow-md flex flex-col gap-4 p-6 md:w-1/2 text-center text-2xl font-semibold w-full"
        >
          Compare Plans Near Me
          <ClickToCall
            v-slot="{ handleClick }"
            :props="{ ctcLocation: 'shop-4-benefits last step' }"
          >
            <a
              :href="phone.uri"
              @click="handleClick"
              :class="[
                'flex justify-center p-2 bg-red rounded-md flex gap-2 !text-white !bg-blue-500	px-4 items-center shadow-lg',
              ]"
            >
              <div class="bg-white rounded-full p-2">
                <Icon
                  i-heroicons-solid:phone
                  class="w-5 h-5 animate-shake text-blue-500"
              /></div>
              <div class="flex flex-col text-lg">
                Call {{ phone.formatted }}
                <span class="text-sm"></span>
              </div>
            </a>
          </ClickToCall>
        </div>
      </div>

      <h2 class="text-center text-xl md:text-3xl font-bold mt-4">
        <span class="text-green-600">If You Are Eligible,</span>
        A Medicare Advantage Grocery Allowance Card Can Be Used For:
      </h2>
      <img
        src="../../assets/buyback-couple.png"
        class="w-1/2 rounded-xl m-auto"
        alt="Elderly smiling couple in a grocery store"
      />
      <div
        class="benefits-list flex flex-col items-start md:px-24 px-2 gap-4 font-semibold text-xl"
      >
        <div class="flex gap-6 items-center">
          <div class="shadow-md bg-white rounded-xl p-4">
            <img
              src="../../assets/grocery-1.svg"
              alt="Healthy Food Icon"
              class="min-w-16"
            />
          </div>
          Healthy Food
        </div>

        <div class="flex gap-6 items-center">
          <div class="shadow-md bg-white rounded-xl p-4">
            <img
              src="../../assets/grocery-fruits-icon.svg"
              alt="Fruits Icon"
              class="min-w-16"
            />
          </div>
          Fresh Produce
        </div>

        <div class="flex gap-6 items-center">
          <div class="shadow-md bg-white rounded-xl p-4">
            <img
              src="../../assets/grocery-otc-health.svg"
              alt="Fruits Icon"
              class="min-w-16"
            />
          </div>
          Over-The-Counter Health Products
        </div>

        <div class="flex gap-6 items-center">
          <div class="shadow-md bg-white rounded-xl p-4">
            <img
              src="../../assets/grocery-rent.svg"
              alt="Fruits Icon"
              class="min-w-16"
            />
          </div>
          Rent/Mortgage Payments
        </div>

        <div class="flex gap-6 items-center">
          <div class="shadow-md bg-white rounded-xl p-4">
            <img
              src="../../assets/grocery-utility.svg"
              alt="Fruits Icon"
              class="min-w-16"
            />
          </div>
          Utility Bills
        </div>
      </div>
      <div
        class="mt-12 bg-white rounded-lg shadow-md flex flex-col gap-4 p-6 md:w-1/2 w-flul text-center text-2xl font-semibold mx-auto"
      >
        Compare Plans Near Me
        <ClickToCall
          v-slot="{ handleClick }"
          :props="{ ctcLocation: 'shop-4-benefits last step' }"
        >
          <a
            :href="phone.uri"
            @click="handleClick"
            :class="[
              'flex justify-center p-2 bg-red rounded-md flex gap-2 !text-white !bg-blue-500 px-4 items-center shadow-lg',
            ]"
          >
            <div class="bg-white rounded-full p-2">
              <Icon
                i-heroicons-solid:phone
                class="w-5 h-5 animate-shake text-blue-500"
            /></div>
            <div class="flex flex-col text-lg">
              Call {{ phone.formatted }}
              <span class="text-sm"></span>
            </div>
          </a>
        </ClickToCall>
      </div>

      <h2 class="text-center text-xl md:text-3xl font-bold mt-4">
        <span class="text-green-600"
          >WE OFFER MEDICARE ADVANTAGE PLANS FROM</span
        >
        Aetna®, Anthem®, Cigna®, Humana®, Molina®, UnitedHealthcare®, Wellcare®
      </h2>
    </div>
    <div class="bg-gray-100">
      <div class="container gap-6 flex flex-col">
        <h2 class="text-center text-xl md:text-3xl font-bold mt-4">
          Compare Medicare Advantage Plans
          <span class="text-green-600">In Your Area</span>
        </h2>
        <div class="text-xl text-center">
          If you qualify, you could be eligible for a Medicare Advantage plan
          that may include a Grocery Allowance Card that can be used to buy
          healthy groceries.
        </div>

        <div class="list-with-icons">
          <img src="../../assets/grocery-family.svg" alt="Family Icon" />
          <div class="flex flex-col font-semibold text-2xl">
            500,000
            <span class="text-base font-normal">Clients Helped</span>
          </div>
        </div>
        <div class="list-with-icons">
          <img src="../../assets/grocery-bird.svg" alt="Staff with bird Icon" />
          <div class="flex flex-col font-semibold text-2xl">
            HIPAA
            <span class="text-base font-normal">Compliant</span>
          </div>
        </div>
        <div class="list-with-icons">
          <img src="../../assets/grocery-usa.svg" alt="USA Flag Icon" />
          <div class="flex flex-col font-semibold text-2xl">
            USA
            <span class="text-base font-normal">Based</span>
          </div>
        </div>
        <div class="list-with-icons">
          <div class="flex items-center justify-center">
            <img src="../../assets/grocery-flame.svg" alt="Flame Icon" />
          </div>
          <div class="flex flex-col font-semibold text-2xl">
            A
            <span class="text-base font-normal">BBB Rating</span>
          </div>
        </div>
        <ClickToCall
          v-slot="{ handleClick }"
          :props="{ ctcLocation: 'shop-4-benefits last step' }"
        >
          <a
            :href="phone.uri"
            @click="handleClick"
            :class="[
              'flex justify-center p-2 bg-red rounded-md flex gap-2 !text-white !bg-blue-500 px-4 items-center md:w-1/2 m-auto shadow-lg',
            ]"
          >
            <div class="bg-white rounded-full p-2">
              <Icon
                i-heroicons-solid:phone
                class="w-5 h-5 animate-shake text-blue-500"
            /></div>
            <div class="flex flex-col text-lg">
              Call {{ phone.formatted }}
              <span class="text-sm"></span>
            </div>
          </a>
        </ClickToCall>
        <img
          src="../../assets/call-center.png"
          alt="Smiling woman working in a call center"
          class="w-1/2 m-auto"
        />
      </div>
    </div>
    <div class="container py-12">
      <h2 class="text-center text-xl md:text-3xl font-bold mt-4">
        We’ve Helped
        <span class="text-green-600">More Than 500,000 People</span> Get Access
        To Medicare Advantage Benefits
      </h2>
    </div>
    <div class="bg-gray-100">
      <div class="container gap-6 flex flex-col">
        <h2 class="text-center text-xl md:text-3xl font-bold mt-4">
          <span class="text-green-600">Medicare Advantage:</span><br />
          One Plan, Many Benefits
        </h2>

        <div class="plan-outer-container">
          <h3 class="plan-title">Medicare Advantage</h3>

          <div class="plan-inner-blue-container">
            <h3 class="plan-title blue">Original Medicare Advantage</h3>

            <div
              class="bg-white rounded-xl p-1 m-auto w-24 flex justify-center shadow-md"
            >
              <img src="../../assets/grocery-bed.svg" alt="Hospital Bed Icon" />
            </div>
            <div class="flex flex-col text-xl font-semibold gap-1 py-8">
              Part A
              <span class="text-sm font-normal">
                Primarily Covers <br />Hospital Stays And <br />Other Inpatient
                Care
              </span>
            </div>
            <div
              class="bg-white rounded-xl p-1 m-auto w-24 flex justify-center shadow-md"
            >
              <img
                src="../../assets/grocery-ambulance.svg"
                alt="Ambulance Icon"
              />
            </div>
            <div class="flex flex-col text-xl font-semibold gap-1 py-8">
              Part B
              <span class="text-sm font-normal">
                Covers Medically Necessary <br />Doctor's Services, Outpatient
                Care,<br />
                Lab Screening Tests, Etc.
              </span>
            </div>
            <div class="double">
              <div
                class="upper-arrow border-l-2 rounded-3xl border-dashed border-gray-400 h-30 rounded w-1/2 ml-12 mt-12"
              ></div>
              <span class="plus rounded-full bg-white p-2 -mt-4 -mb-4 z-4">
                <Icon i-heroicons-solid:plus class="w-8 h-8" />
              </span>
              <div
                class="bottom-arrow border-l-2 rounded-3xl border-dashed border-gray-400 h-30 rounded w-1/2 ml-12 -mt-4"
              ></div>
            </div>
          </div>
          <div class="py-12">
            <div
              class="bg-white rounded-xl p-1 m-auto w-24 flex justify-center shadow-md"
            >
              <img src="../../assets/grocery-tooth.svg" alt="Ambulance Icon" />
            </div>

            <div class="flex flex-col text-xl font-semibold gap-1 py-8">
              Part C
              <span class="text-sm font-normal">
                May Include Dental, <br />Hearing Or Vision Coverage
              </span>
            </div>
          </div>
        </div>
        <div class="plan-outer-container-2">
          <div
            class="w-full h-4 absolute px-4 -top-2 z-4 flex items-center justify-center"
          >
            <span class="plus rounded-full bg-white p-2 -ml-2 shadow-md">
              <Icon i-heroicons-solid:plus class="w-8 h-8" />
            </span>
          </div>

          <div class="py-16 space-y-8">
            <div
              class="bg-white rounded-xl p-1 m-auto w-24 flex justify-center shadow-md"
            >
              <img src="../../assets/grocery-hands.svg" alt="Ambulance Icon" />
            </div>

            <div class="flex flex-col text-xl font-semibold gap-1">
              Part D
              <span class="text-sm font-normal text-gray-800 font-sans">
                May Include Dental, <br />Hearing Or Vision Coverage
              </span>
            </div>
          </div>
        </div>

        <ClickToCall
          v-slot="{ handleClick }"
          :props="{ ctcLocation: 'shop-4-benefits last step' }"
        >
          <a
            :href="phone.uri"
            @click="handleClick"
            :class="[
              'flex justify-center p-2 bg-red rounded-full border !border-green-500 !bg-white flex gap-2	px-4 items-center font-bold',
            ]"
          >
            <div class="flex flex-col text-lg">
              Plans With Low or $0 <br />Monthly Premiums
              <span class="text-sm"></span>
            </div>
          </a>
        </ClickToCall>

        <div class="flex flex-col items-center justify-center py-6 gap-4">
          <h2 class="text-xl font-semibold">Ready To Find A Plan? </h2>
          <span class="text-lg">Compare Plans In Your Area In Minutes </span>
          <ClickToCall
            v-slot="{ handleClick }"
            :props="{ ctcLocation: 'shop-4-benefits last step' }"
          >
            <a
              :href="phone.uri"
              @click="handleClick"
              :class="[
                'flex justify-center p-2 bg-red rounded-md flex gap-2 !text-white !bg-blue-500	px-4 items-center shadow-lg',
              ]"
            >
              <div class="bg-white rounded-full p-2">
                <Icon
                  i-heroicons-solid:phone
                  class="w-5 h-5 animate-shake text-blue-500"
              /></div>
              <div class="flex flex-col text-lg">
                Call {{ phone.formatted }}
                <span class="text-sm"></span>
              </div>
            </a>
          </ClickToCall>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.list-with-icons {
  @apply grid rounded-xl bg-white p-4 gap-8 items-center;
  grid-template-columns: 4rem 1fr;
}

.plan-outer-container,
.plan-outer-container-2 {
  @apply p-2 rounded-xl border-2 border-dashed	border-green-600 text-center md:max-w-md md:w-1/2 m-auto w-full bg-gray-50;
}

.plan-outer-container {
  @apply z-3;
}
.plan-outer-container-2 {
  @apply -mt-7 border-t-0 relative;
}
.plan-title {
  @apply bg-gray-50 p-2 text-green-600 w-fit	m-auto text-xl font-semibold text-nowrap;
  margin-top: -30px;
}
.plan-inner-blue-container {
  @apply p-2 rounded-xl border-2 border-blue-500 text-center max-w-md m-auto mt-4 relative mx-4;
}
.plan-title.blue {
  @apply text-blue-500;
}
.double {
  position: absolute;
  height: 340px;
  width: 100px;
  @apply flex flex-col items-center md:top-[22px] md:left-[10px] top-[14px] -left-2;
}

.upper-arrow::before,
.bottom-arrow::before {
  position: absolute;
  content: ' ';
  width: 0px;
  height: 0px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid rgba(156, 163, 175, var(--un-border-opacity));
}
.upper-arrow::before {
  top: 42px;
  right: 24px;
}
.bottom-arrow::before {
  content: ' ';
  bottom: 46px;
  right: 24px;
}
</style>
