<script setup lang="ts">
useHead({
  title: 'Congrats - Medicare Part C Pre-Approval Complete',
})

analytics.page({
  name: 'Medicare TYP 2.0',
  category: 'TYP',
  product: 'medicare',
})

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <Layout class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Get up to <span class="text-red"> $170 back every month </span> on your
        social security check with new 2023 Medicare Part C plans
      </div>

      <img
        src="https://uploads-ssl.webflow.com/600f3fa75e3ffa05fa6431e7/62b4ad1aee24dc3589e403b3_AdobeStock_458398578.jpeg"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div class="space-y-6">
        <div class="text-2xl sm:text-3xl text-red-500 text-center">
          You pre-qualified for plans with extra benefits like prescriptions,
          dental, vision, and more
        </div>

        <div class="text-3xl sm:text-4xl text-center">
          Call the {{ brand.name }}.
        </div>

        <ClickToCall />

        <div class="text-lg sm:text-xl font-medium text-center">
          Your agent has joined the call, click to connect now.
          <span class="text-red"> Request expires in {{ timer }} </span>
        </div>
      </div>

      <Carriers />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "ayb"],
    "smid": "MULTIPLAN_EH2022FB0623v1_M",
    "robots": "noindex"
  }
}
</route>
