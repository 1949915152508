import { StorageSerializers } from '@vueuse/core'

interface IpLookup {
  value: string | null
  isp: string | null
  cityName: string | null
  stateCode: string | null
  stateName: string | null
  countryCode: string | null
  countryName: string | null
  postalCode: string | null
  timeZone: string | null
  latitude: number | null
  longitude: number | null
  accuracyRadius: number | null
}

interface Ip {
  loading: boolean
  lookup: IpLookup | null
}

const ipLookup = useLocalStorage<IpLookup | null>('ipLookup', null, {
  serializer: StorageSerializers.object,
})

export const ip: Ip = reactive({
  loading: false,
  lookup: ipLookup,
})

onInit(({ isClient }) => {
  if (isClient) {
    const { load } = useScriptTag(
      'https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js',
      () => {
        ip.loading = true
        window.geoip2.city(
          (res: any) => {
            const states = [
              4197000, 5037779, 5001836, 5509151, 4155751, 5667009, 5843591,
              6254925, 4544379, 4736286, 4896861, 4829764, 4436296, 4099753,
              4398678, 4273857, 5073708, 4971068, 5279468, 5879092, 5596512,
              5744337, 5551752, 5417618, 5549030, 5815135, 6254928, 4862182,
              4331987, 5332921, 5855797, 5481136, 4826850, 4482348, 5128638,
              5769223, 5690763, 4662168, 5165418, 6254926, 4361885, 5242283,
              4921868, 6254927, 5090174, 4597040, 4142224, 4831725, 5101760,
              5224323,
            ]
            const state = res.subdivisions?.find((sub: any) => {
              return states.includes(sub.geoname_id)
            })

            ipLookup.value = {
              value: res.traits?.ip_address || null,
              isp: res.traits?.isp || null,
              cityName: res.city?.names?.en || null,
              stateCode: state?.iso_code || null,
              stateName: state?.names?.en || null,
              countryCode: res.country?.iso_code || null,
              countryName: res.country?.names?.en || null,
              postalCode: res.postal?.code || null,
              timeZone: res.location?.time_zone || null,
              latitude: res.location?.latitude || null,
              longitude: res.location?.longitude || null,
              accuracyRadius: res.location?.accuracy_radius || null,
            }

            ip.loading = false
          },
          () => {
            ip.loading = false
          }
        )
      },
      {
        manual: true,
      }
    )
    load()
  }
})
