<script setup lang="ts">
useHead({
  title: `Flex Card - ${brand.name}`,
})

const healthPlans = {
  aa6: 1600,
  b10: 10,
  cd0: 0,
}

const route = useRoute()
const healthInsurancePlan = ref(1600)

if (route.query.healthPlan) {
  const planKey = route.query.healthPlan as keyof typeof healthPlans
  if (planKey && healthPlans.hasOwnProperty(planKey)) {
    healthInsurancePlan.value = healthPlans[planKey]
  } else {
    healthInsurancePlan.value = 1600
  }
}

const isPaidUser = ref(true)
const router = useRouter()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  if (!isPaidUser.value) {
    router.push('/go-4-benefits')
    return
  }
})

provide('isPaidUser', isPaidUser)
provide('healthInsurancePlan', healthInsurancePlan)

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.top5Otc !== null) {
    return {
      code: state.code,
      name: state.name,
      top5Otc: `$${state.top5Otc}`,
      medicaid: state.medicaid,
    }
  }
})

const currStep = ref<string>()

analytics.page({
  name: `ACA Eligibility $${healthInsurancePlan.value}`,
  category: 'LP',
  product: 'aca',
})
</script>

<template>
  <Layout :hide-header="true" footer="aca" class="container min-h-screen">
    <div class="max-w-3xl mx-auto space-y-4 py-4 font-dm text-center">
      <template v-if="currStep !== 'loading' && currStep !== 'call'">
        <h1 class="text-xl font-semibold text-center uppercase">
          Attention AMERICANS UNDER 65:
        </h1>
        <div class="text-3xl" v-if="healthInsurancePlan === 1600">
          Get your
          <span class="text-green-500 underline font-semibold">
            ${{ healthInsurancePlan }}
          </span>
          Healthcare Subsidy Today!
        </div>
        <div class="text-3xl" v-else>
          Qualify For Health Insurance Plans with High Coverage for
          <span class="text-green-500 underline font-semibold">
            ${{ healthInsurancePlan }}
          </span>
          PER MONTH!
        </div>
        <div v-if="healthInsurancePlan === 1600">
          Residents of <b>{{ state?.name ?? 'America' }}</b> Who are
          <b>NOT</b> on {{ state?.medicaid ?? 'Medicaid' }} May Claim up to
          <b
            ><i>${{ healthInsurancePlan }} in Healthcare Subsidies</i></b
          >
          for Health Insurance today, thanks to the Affordable Care Act
        </div>
        <div v-else>
          Residents of <b>{{ state?.name ?? 'America' }}</b> Who are
          <b>NOT</b> on {{ state?.medicaid ?? 'Medicaid' }} May Claim their
          <b>
            <i>${{ healthInsurancePlan }} Health Insurance</i>
          </b>
          today, thanks to the Affordable Care Act
        </div>
        <img
          src="../../assets/aca-benefits-treasury-check.jpg"
          class="w-full h-full"
          alt="Allowance Treasury Check"
        />
      </template>

      <div
        :class="[
          currStep !== 'loading' &&
            currStep !== 'call' &&
            '-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0 relative bg-[#9BD2FA] py-4',
        ]"
      >
        <h2 class="text-xl font-semibold px-4">
          Quick Quiz: See if you qualify
        </h2>
        <Form
          id="click4OtcPaid"
          :class="[
            currStep !== 'loading' && currStep !== 'call' && 'p-4 sm:p-6',
            '!space-y-4 !max-w-3xl',
          ]"
          scrollBehavior="auto"
          :steps="[{ id: 'medicaid' }, { id: 'call', completed: true }]"
          product="medicare"
          @step-change="currStep = $event.id"
        />
      </div>

      <template v-if="currStep !== 'loading' && currStep !== 'call'">
        <div
          class="flex items-center justify-center gap-4 bg-gray-200 border border-gray-300 p-4"
        >
          <Icon
            i-heroicons-outline:exclamation
            class="w-6 h-6 text-gray-700 shrink-0"
          />
          <div>
            Compare Affordable Care Act Plans and Benefits from Humana, Aetna,
            and UnitedHealthcare®.
          </div>
        </div>

        <div class="flex flex-wrap justify-center gap-2">
          <div
            v-for="tag in ['Fact checked', 'Expert reviewed']"
            :key="tag"
            class="border border-gray-300 rounded-full py-1 px-3 flex items-center gap-1 whitespace-nowrap uppercase text-sm font-medium"
          >
            <div> {{ tag }} </div>
            <Icon i-heroicons-solid:check class="text-blue-600" />
          </div>
        </div>
      </template>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["ayb"],
    "smid": {
      "bh": "PLAN_EHWEB082329_M"
    },
    "robots": "noindex"
  }
}
</route>
