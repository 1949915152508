<script setup lang="ts">
useHead({
  title: `See if you qualify - ${brand.name}`,
})

const showFooterPhone = ref(true)

const variant = ref<string>()

provide('variant', variant)

onMounted(() => {
  if (brand.id === 'med10') {
    variant.value = 'default'
    return
  }
  // if (query.gclid || query.fbclid || query.token) {
  //   enterExperiment('go4BenefitsCopy')
  //   variant.value = exp.value?.go4BenefitsCopy?.groceryAllowance900
  //     ? 'groceryAllowance900'
  //     : 'spendingAllowances3300'
  // } else {
  //   variant.value = 'default'
  // }
  variant.value = 'default'
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout header="ping" footer="go4Benefits" class="container flex flex-col">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Change in Medicaid Status? </Banner>
    </template>
    <div class="flex-1 py-16">
      <Form
        id="go4Benefits"
        :steps="[
          { id: 'start' },
          { id: 'medicareab' },
          { id: 'medicaid' },
          { id: 'benefit' },
          { id: 'loading' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_EHWEB072306_C",
      "med10": "MULTI-PLAN_EHWEB072308_C",
      "boomer": "MULTI-PLAN_EHWEB072309_C"
    }
  }
}
</route>
