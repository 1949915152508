<template>
  <div class="flex gap-1">
    <div class="p-3 rounded-full bg-gray-200">
      <Icon i-simple-icons:facebook class="w-5 h-5 text-blue-600" />
    </div>
    <div class="p-3 rounded-full bg-gray-200">
      <Icon i-simple-icons:twitter class="w-5 h-5 text-blue-600" />
    </div>
    <div class="p-3 rounded-full bg-gray-200">
      <Icon i-simple-icons:linkedin class="w-5 h-5 text-blue-600" />
    </div>
  </div>
</template>
