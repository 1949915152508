<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
const props = defineProps<{
  targetValue: number
}>()

const emit = defineEmits<{
  (e: 'done'): void
}>()

const source = ref(0)
const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

onMounted(() => {
  setTimeout(() => {
    source.value = props.targetValue ?? 1000
  }, 0)
})

const card = ref(null)
const { height } = useElementSize(card)

const name = computed(() => {
  const firstname = query.firstname?.trim() || ''
  const lastname = query.lastname?.trim() || ''
  const fullName = `${firstname} ${lastname}`.trim()
  return fullName.length > 15 && firstname && lastname
    ? `${firstname.charAt(0)} ${lastname}`
    : fullName
})
</script>

<template>
  <div ref="card" class="aspect-[380/253] max-w-[380px] translate relative">
    <img
      src="../assets/allowance-card-blank.png"
      class="object-cover"
      alt="Blue Allowance Card"
    />
    <div
      class="absolute left-[8%] bottom-[11%] w-[62%] text-white uppercase font-sans font-normal"
      :style="{
        fontSize: `${height * 0.1}px`,
        lineHeight: `${height * 0.15}px`,
      }"
    >
      <template v-if="name">
        <Typewriter
          :text="name"
          class="truncate"
          cursor=""
          :speed="75"
          @done="emit('done')"
        />
      </template>
      <div v-else class="truncate"> Emily Taylor </div>
    </div>
  </div>
</template>
