<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center">
    Are you currently enrolled in Medicare Parts A and B?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicare = null"
    >
      Not sure
    </Button>
  </div>
</template>
