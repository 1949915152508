export type PlanRowDTO = {
  plan_id: string
  carrier: string
  carrier_group: string
  plan_name: string
  plan_type: string
  benefits: Benefit[]
  enrollments: number
  enrollments_prev_year: number
  growth: number
  premium?: string
  stars: number
}

export type BenefitView = {
  name: string
  description: string
  unavailable?: boolean
  amount?: number
}

export type PlanRowView = Omit<PlanRowDTO, 'benefits' | 'premium'> & {
  open: boolean
  carrierGroupColor: string
  benefits: BenefitView[]
  premium: number
}

export type Benefit = {
  dental_summary?: DentalSummary
  otc_summary?: OtcSummary
  vision_summary?: VisionSummary
  giveback_summary?: GiveBackSummary
  allowance_card?: GiveBackSummary
  flex_card?: GiveBackSummary
  rent_assistance?: RentAssistanceSummary
}

type DentalSummary = {
  pbp_b16a_bendesc_yn: string
}

type OtcSummary = {
  pbp_b13b_maxplan_yn: string
  pbp_b13b_maxplan_amt: string
  pbp_b13b_maxplan_prd_yn: string
  pbp_b13b_otc_maxplan_per: string
}

type VisionSummary = {
  provides_eyewear: string
  provides_eye_exams: string
}

type GiveBackSummary = {
  pbp_d_mco_pay_reduct_amt: number
  pbp_d_mco_pay_reduct_yn: 'Yes' | 'No'
}

type RentAssistanceSummary = {
  pbp_d_mco_pay_reduct_amt: number
  pbp_d_mco_pay_reduct_yn: 'Yes' | 'No'
}

export type getPlansParams = {
  zipCode?: string
  stateCode?: string
  benefits?: string[]
  amount: number
  starting_from: number
  carrier_groups?: string[]
}

export const getPlans = async (
  params: getPlansParams
): Promise<PlanRowView[]> => {
  const {
    zipCode,
    stateCode,
    benefits,
    amount,
    starting_from,
    carrier_groups,
  } = params
  if (!zipCode && !stateCode) return []

  let url =
    import.meta.env.VITE_WEBSITE_API_URL +
    `/plans?amount=${amount}&starting_from=${starting_from}`
  if (stateCode) {
    url += `&state=${stateCode}`
  }
  if (zipCode) {
    url += `&zip_code=${zipCode}`
  }

  if (benefits && benefits.length > 0) {
    benefits.forEach((benefit) => {
      url += `&benefits[]=${benefit}`
    })
  }

  if (carrier_groups && carrier_groups.length > 0) {
    carrier_groups.forEach((group) => {
      url += `&carriers_groups[]=${group}`
    })
  }

  const { data } = await axios.get<PlanRowDTO[]>(url)
  const formatted: PlanRowView[] = data.map((plan, index) => {
    // const benefits = plan.benefits
    // benefits.push({
    //   allowance_card: {
    //     pbp_d_mco_pay_reduct_amt: 76,
    //     pbp_d_mco_pay_reduct_yn: 'Yes',
    //   },
    // })
    // benefits.push({
    //   flex_card: {
    //     pbp_d_mco_pay_reduct_amt: 51,
    //     pbp_d_mco_pay_reduct_yn: 'Yes',
    //   },
    // })

    return {
      ...renamePlans(plan),
      carrierGroupColor: getCarrierColor(plan),
      growth: plan.growth ?? 0,
      benefits: formatBenefits(plan.benefits),
      open: false,
      premium: plan.premium ? parseFloat(plan.premium) : 0,
      stars: plan.stars ?? 0,
    }
  })
  return formatted
}

const renamePlans = (plan: PlanRowDTO) => {
  switch (plan.carrier) {
    case 'Blues':
      return {
        ...plan,
        carrier: `Anthem / Blue Cross`,
      }
    case 'United':
      return {
        ...plan,
        carrier: `UnitedHealthcare®`,
      }
    default:
      return plan
  }
}

const formatBenefits = (benefits: Benefit[]): BenefitView[] => {
  return benefits
    .map((benefit) => {
      if (benefit.dental_summary) {
        return {
          name: 'Dental',
          description: '',
          unavailable:
            benefit.dental_summary.pbp_b16a_bendesc_yn.toLowerCase() === 'no',
        }
      } else if (benefit.vision_summary) {
        let description = []
        if (benefit.vision_summary.provides_eyewear === 'Yes') {
          description.push('Eyewear')
        }
        if (benefit.vision_summary.provides_eye_exams === 'Yes') {
          description.push('Exams')
        }
        return {
          name: 'Vision',
          description:
            description.length > 0
              ? `Provides ${description.join(', ')}`
              : 'No Vision Benefits',
        }
      } else if (benefit.otc_summary) {
        let description = 'No OTC Benefits'
        if (
          benefit.otc_summary.pbp_b13b_maxplan_amt &&
          benefit.otc_summary.pbp_b13b_otc_maxplan_per
        ) {
          description = `Up to <span class="text-red text-3xl">${USDollarFormatter(
            0
          ).format(
            Number(benefit.otc_summary.pbp_b13b_maxplan_amt)
          )}</span> ${benefit.otc_summary.pbp_b13b_otc_maxplan_per.toLowerCase()}`
        }
        return {
          name: 'OTC',
          description,
          amount: Number(benefit.otc_summary.pbp_b13b_maxplan_amt),
        }
      } else if (benefit.giveback_summary) {
        let description = ''
        if (
          benefit.giveback_summary.pbp_d_mco_pay_reduct_amt &&
          benefit.giveback_summary.pbp_d_mco_pay_reduct_yn === 'Yes'
        ) {
          description = `Up to <span class="text-red text-3xl">${USDollarFormatter(
            0
          ).format(
            Number(benefit.giveback_summary.pbp_d_mco_pay_reduct_amt * 12)
          )}/Yr</span>`
        }
        return {
          name: 'Giveback',
          description,
          unavailable:
            benefit.giveback_summary.pbp_d_mco_pay_reduct_yn.toLowerCase() ===
            'no',
          amount: benefit.giveback_summary.pbp_d_mco_pay_reduct_amt,
        }
      } else if (benefit.allowance_card) {
        let description = ''
        if (
          benefit.allowance_card.pbp_d_mco_pay_reduct_amt &&
          benefit.allowance_card.pbp_d_mco_pay_reduct_yn === 'Yes'
        ) {
          description = `Up to <span class="text-red text-3xl">${USDollarFormatter(
            0
          ).format(
            Number(benefit.allowance_card.pbp_d_mco_pay_reduct_amt * 12)
          )}/Yr</span>`
        }
        return {
          name: 'Allowance Card',
          description,
          unavailable:
            benefit.allowance_card.pbp_d_mco_pay_reduct_yn.toLowerCase() ===
            'no',
        }
      } else if (benefit.flex_card) {
        let description = ''
        if (
          benefit.flex_card.pbp_d_mco_pay_reduct_amt &&
          benefit.flex_card.pbp_d_mco_pay_reduct_yn === 'Yes'
        ) {
          description = `Up to <span class="text-red text-3xl">${USDollarFormatter(
            0
          ).format(
            Number(benefit.flex_card.pbp_d_mco_pay_reduct_amt * 12)
          )}/Yr</span>`
        }
        return {
          name: 'Flex Card',
          description,
          unavailable:
            benefit.flex_card.pbp_d_mco_pay_reduct_yn.toLowerCase() === 'no',
        }
      } else if (benefit.rent_assistance) {
        let description = ''
        if (
          benefit.rent_assistance.pbp_d_mco_pay_reduct_amt &&
          benefit.rent_assistance.pbp_d_mco_pay_reduct_yn === 'Yes'
        ) {
          description = `Up to <span class="text-red text-3xl">${USDollarFormatter(
            0
          ).format(
            Number(benefit.rent_assistance.pbp_d_mco_pay_reduct_amt * 12)
          )}/Yr</span>`
        }
        return {
          name: 'Rent Assistance',
          description,
          unavailable:
            benefit.rent_assistance.pbp_d_mco_pay_reduct_yn.toLowerCase() ===
            'no',
        }
      } else {
        return {
          name: 'Unknown',
          description: '',
        }
      }
    })
    .filter((item) => item.name !== 'Unknown')
    .sort()
}

export const getCarrierColor = (plan: PlanRowDTO) => {
  switch (plan.carrier_group) {
    case 'Aetna':
      return '#7B2795'
    case 'Blues':
      return '#009EDF'
    case 'Humana':
      return '#388600'
    case 'Kaiser':
      return '#006382'
    case 'Molina':
      return '#00A4B2'
    case 'United':
      return '#00277C'
    case 'Wellcare':
      return '#00A3A2'
    default:
      return `#003459`
  }
}

export type Top5OtcResponse = {
  average: number
  min_otc: number
}

export type getTop5OtcParams = {
  zip_code: string
  is_dsnp: boolean
}
export const getTop5Otcs = async (
  params: getTop5OtcParams
): Promise<Top5OtcResponse> => {
  const { zip_code, is_dsnp = true } = params
  let url =
    import.meta.env.VITE_WEBSITE_API_URL +
    `/plans/get-top-5-otc?zip_code=${zip_code}&is_dsnp=${is_dsnp}`

  const { data } = await axios.get<Top5OtcResponse>(url)

  return data
}
