<script setup lang="ts">
const router = useRouter()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  router.push(`/affordable-healthcare-3/?zip=${values.zip}`)
})
</script>

<template>
  <form
    @submit="onSubmit"
    class="grid grid-cols-[repeat(auto-fit,minmax(15.5rem,1fr))] items-start gap-4"
  >
    <Field
      type="zip"
      name="zip"
      rules="required"
      placeholder="Enter ZIP Code"
      class="text-center"
    />
    <Button wide type="submit" class="whitespace-nowrap"> Shop Plans </Button>
  </form>
</template>
