<script setup lang="ts">
import type { Ref } from 'vue'

const lowBid = inject('lowBid') as Ref<boolean>
const clickTyp = inject<Ref<boolean>>('clickTyp')!

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <template v-if="clickTyp || lowBid">
    <FormProgress class="!mb-6" />

    <div class="text-3xl sm:text-4xl text-center text-red font-bold">
      Congratulations!
    </div>

    <div class="text-2xl sm:text-3xl text-center">
      You Pre-qualify For Up To $1400 Subsidy & $0 Health Insurance!
    </div>

    <MediaAlphaAd placementId="FIh9OLNZSX1gkuzOIAh_qiPMpKJIJA" :user="{}" />
  </template>
  <template v-else>
    <FormProgress class="!mb-6" />

    <div class="text-3xl sm:text-4xl text-center text-red font-bold">
      Congratulations!
    </div>

    <div class="text-2xl sm:text-3xl text-center">
      You Pre-qualify For Up To $1400 Subsidy & $0 Health Insurance!
    </div>

    <div class="text-3xl sm:text-4xl text-center">
      Click Below To Claim Your Benefits Now!
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

    <div class="text-lg sm:text-xl font-medium text-center">
      Act quickly!
      <br />
      This pre-approval expires in <span class="text-red"> {{ timer }} </span>
    </div>
  </template>
</template>
