<script setup lang="ts">
const { value: makeMoreThan } = useField('makeMoreThan')
</script>

<template>
  <div class="text-3xl sm:text-4xl text-center">
    Do you make more than $25k per year?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="undefined"
      :class="[
        'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px] !pt-2',
        'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
        'transition-all duration-150 ',
      ]"
      @click="makeMoreThan = false"
    >
      <span
        class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
      >
        No
      </span>
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="undefined"
      :class="[
        'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px] !pt-2',
        'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
        'transition-all duration-150 ',
      ]"
      @click="makeMoreThan = true"
    >
      <span
        class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
      >
        Yes
      </span>
    </Button>
    <!-- <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="makeMoreThan = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="makeMoreThan = false"
    >
      No
    </Button> -->
  </div>
</template>
