<script setup lang="ts">
const props = defineProps<{
  name: string
}>()

const components: any = _.mapKeys(
  import.meta.glob('./*.vue', { eager: true }),
  (value, key) => _.camelCase(key.slice(5, -4))
)

const component = computed(() => {
  return components[props.name].default
})
</script>

<template>
  <component :is="component" />
</template>
