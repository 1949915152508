<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `Affordable Healthcare - ${brand.name}`,
})

const date = ref()

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
})

const route = useRoute()
const clickTyp = ref(false)

provide('clickTyp', clickTyp)

onMounted(() => {
  clickTyp.value = route.query.typ === 'clicks'
})

const lowBid = ref(false)

provide('lowBid', lowBid)
</script>

<template>
  <Layout hideHeader footer="aca" class="container">
    <div class="max-w-3xl mx-auto space-y-6 pt-4 pb-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <div class="text-xl leading-6.5 sm:text-3xl font-bold text-center">
        Get your <span class="text-red">$1400 Healthcare Subsidy</span>. Secure
        Health Coverage for <span class="text-red">$0 Monthly</span>!
      </div>

      <div class="text-lg sm:text-2xl text-center">
        Residents of
        <span class="text-red">{{ ip.lookup?.stateName || 'America' }}</span
        >: Claim up to $1,400 in Premium Tax Credits for Health Insurance
        <span class="text-red">today</span>, courtesy of the Affordable Care
        Act!
      </div>

      <img
        src="../../assets/aca-1400.png"
        width="500"
        height="240"
        class="w-[90%] max-w-[400px] sm:w-[400px] mx-auto"
      />

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="affordableHealthcare"
            class="!max-w-3xl py-6 px-4 sm:px-6"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'groupHealth' },
              { id: 'income' },
              { id: 'medicareOrMedicaid' },
              {
                id: 'sorry',
                if: values?.haveMedicareOrMedicaid === true,
              },
              { id: 'us' },
              { id: 'loading' },
              { id: 'call', completed: true },
            ]"
            product="aca"
          />
        </UseForm>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "ayb"],
    "robots": "noindex"
  }
}
</route>
