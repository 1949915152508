<script setup lang="ts">
const route = useRoute()

const benefits = computed(() => {
  return route.meta.benefits as string[] | undefined
})
</script>

<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      We do not offer every plan available in your area. Currently we represent
      over 22 organizations which offer 38 products in your area. Please contact
      Medicare.gov, 1-800-MEDICARE, or your local State Health Insurance Program
      (SHIP) to get information on all of your options.
      <br />
      <br />

      Not all plans offer all of these benefits. Benefits may vary by carrier
      and location. Limitations and exclusions may apply.
      <br />
      <br />

      *The standard Part B premium for 2024 is $174.70 per month. Monthly
      savings varies and may be subject to processing delays and may not be
      immediate. Not available with all plans. Availability varies by carrier
      and location.
      <br />
      <br />

      Allowance amounts cannot be combined with other benefit allowances.
      Limitations and restrictions may apply.
      <br />
      <br />

      Participating sales agencies represent Medicare Advantage HMO, PPO and
      PFFS organization and PDP that are contracted with Medicare. Enrollment
      depends on the plan's contract renewal with Medicare. Not affiliated with
      or endorsed by any government agency.
      <br />
      <br />

      {{ brand.url }} is not connected with or endorsed by the United States
      government or the Federal Medicare program. There is absolutely no
      obligation to purchase anything. At {{ brand.url }} you will talk with a
      licensed insurance agent in your state. This is a solicitation of
      insurance. Enrollment in a plan may be limited to certain times of the
      year unless you qualify for a special enrollment period or you are in your
      Medicare Initial Election Period.
      <br />
      <br />

      To send a complaint to an insurance carrier, call the Plan or the number
      on your member ID card. To send a complaint to Medicare, call
      1-800-MEDICARE (TTY users should call 1-877-486-2048), 24 hours a day/7
      days a week). If your complaint involves a broker or agent, be sure to
      include the name of the person when filing your grievance.
      <br />
      <br />

      By using this site, you acknowledge that you have read and agree to the
      <Link to="/tos">Terms of Service</Link> and
      <Link to="/privacy">Privacy Policy</Link>.
      <br />
      <br />

      <div class="sm:hidden">
        <div class="flex items-center space-x-6 my-2">
          <img src="../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../assets/comodo-badge.svg" width="107" height="34" />
        </div>
        <br />
      </div>

      Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All rights
      reserved | 8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069

      <template v-if="smid">
        <br />
        <br />
        {{ smid }}
      </template>
    </div>
  </div>
</template>
