<script setup lang="ts">
const source = query.utm_source?.toLowerCase()

const sources = [
  'google',
  'facebook',
  'ap',
  'ei',
  'hc',
  'ma',
  'tbl',
  'lgl',
  'ld',
  'slqt',
]
</script>

<template>
  <div
    v-if="source && sources.includes(source)"
    class="flex flex-wrap justify-center"
  >
    <img src="../assets/carriers-1.jpg" />
    <img src="../assets/carriers-2.jpg" />
  </div>
</template>
