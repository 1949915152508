<script setup lang="ts">
//

const tabs = [
  {
    id: 'overview',
    name: 'Overview',
  },
  {
    id: 'prosAndCons',
    name: 'Pros & Cons',
  },
]

const selectedTabId = ref('overview')
</script>

<template>
  <Layout header="ac">
    <div>
      <div class="container py-8 max-w-xl">
        <div class="rounded-md [box-shadow:0_0_6px_rgba(0,0,0,0.3)]">
          <div
            class="mx-4 flex items-center justify-center gap-2 bg-green-700 text-white -translate-y-1/2 py-1 px-2 -mb-3.5"
          >
            <Icon i-heroicons-solid:star />
            <div class="text-xs uppercase font-medium tracking-wide">
              Best for low-cost plan availability
            </div>
          </div>
          <div class="p-8">
            <img
              src="../../assets/carrier-humana.svg"
              width="104"
              height="18"
              class="w-48 mx-auto"
            />
          </div>
          <div class="bg-blue-100 p-4 space-y-2">
            <img
              src="../../assets/allowance-card-humana.png"
              width="398"
              height="250"
              class="w-64 mx-auto rounded-md ring-1 ring-gray-300"
            />
            <div class="flex items-center gap-2 justify-center">
              <div class="text-sm font-medium"> Our rating: </div>
              <div class="flex">
                <RatingStars :rating="4" :max-range="5" />
              </div>
              <div class="text-lg text-blue-600 font-medium"> 4.3 </div>
            </div>
          </div>
          <div class="p-4 space-y-4">
            <div class="text-center">
              Humana plans are widely available, highly rated by CMS and score
              well for member satisfaction. Humana also offers $0-premium plans
              in 49 states, Washington, D.C., and Puerto Rico.
            </div>

            <div class="p-4 space-y-2 bg-gray-100 rounded-md relative">
              <div
                class="absolute top-1 right-2 text-sm text-gray-400 font-medium"
              >
                Ad
              </div>
              <div class="flex items-center justify-center gap-2">
                <div class="bg-gray-200 p-1.5 rounded-full">
                  <Icon i-heroicons-solid:phone class="text-green-700" />
                </div>
                <div class="text-lg font-semibold text-gray-600">
                  <span class="text-green-700"> {{ phone.formatted }} </span>
                  / TTY 711
                </div>
              </div>
              <div class="text-sm text-center">
                8am - 11pm EST, 7 days a week
              </div>
              <div class="text-sm text-center text-gray-500">
                Speak to a licensed insurance agent on Benefit-Helpline.com
              </div>
            </div>

            <div class="space-y-1">
              <Button wide size="sm"> GET A QUOTE </Button>
              <div class="text-sm text-center text-gray-500">
                from Benefit-Helpline.com
              </div>
            </div>

            <div class="flex gap-2 justify-center">
              <button
                v-for="tab in tabs"
                :key="tab.id"
                :class="[
                  tab.id === selectedTabId
                    ? 'bg-blue-100 border-blue-300'
                    : 'bg-gray-100 border-gray-300',
                  'flex-1 rounded-md border py-2 px-4',
                ]"
                @click="selectedTabId = tab.id"
              >
                {{ tab.name }}
              </button>
            </div>

            <div v-if="selectedTabId === 'overview'">
              <div class="space-y-1">
                <div class="text-sm text-gray-500"> States available </div>
                <div class="font-medium">
                  49 states, Washington, D.C., and Puerto Rico
                </div>
              </div>
              <div class="h-px bg-gray-200 my-3"></div>
              <div class="space-y-1">
                <div class="text-sm text-gray-500">
                  Members in high-rated plans
                </div>
                <div class="font-medium"> High (90% or more) </div>
              </div>
              <div class="h-px bg-gray-200 my-3"></div>
              <div class="space-y-1">
                <div class="text-sm text-gray-500"> Member experience </div>
                <div class="font-medium"> 3.97 (Above average) </div>
              </div>
            </div>

            <div v-if="selectedTabId === 'prosAndCons'">
              <div class="mb-1 font-medium"> Pros </div>
              <ul class="list-disc list-outside pl-4 text-gray-600">
                <li>
                  Offers $0-premium plans in every U.S. state except Alaska.
                </li>
                <li> Higher-than-average overall star rating from CMS. </li>
                <li>
                  Strong member experience scores among major providers.
                </li>
              </ul>
              <div class="mt-4 mb-1 font-medium"> Cons </div>
              <ul class="list-disc list-outside pl-4 text-gray-600">
                <li>
                  Chronic Condition Special Needs Plans available in only 22
                  states.
                </li>
                <li>
                  The National Committee for Quality Assurance gives several
                  Humana plans a 3 or below on its 5-point scale.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="container py-8">
        <Article>
          <h1> What Is a Medicare Flex Card for Seniors? </h1>

          <ArticleWrittenBy />

          <ArticleInfoCard>
            <h2> Key takeaways: </h2>
            <ul>
              <li>
                The Medicare flex card is a prepaid debit card that helps
                eligible seniors pay for out-of-pocket healthcare costs and
                other approved items.
              </li>
              <li>
                Medicare does not issue the flex card. To get the card, you'll
                need to enroll in a Medicare Advantage plan that offers the
                program.
              </li>
              <li>
                Not everyone in a Medicare Advantage plan will qualify for a
                flex card. Your eligibility and the amount of money you receive
                depend on your insurance carrier, the plan, and where you live.
              </li>
            </ul>
          </ArticleInfoCard>

          <img
            src="../../assets/helpline-seniors-hands-up.png"
            width="1254"
            height="836"
          />

          <p>
            Turning 65 comes with many benefits. You gain access to Medicare and
            may choose to retire. But if you haven't saved enough money, it may
            be challenging to cover your expenses if you stop working.
          </p>

          <p>
            Enter the Medicare flex card. This card can help reduce your
            out-of-pocket costs and save money on healthcare. But being a
            Medicare beneficiary is not enough to get one, and there are
            restrictions on what you can buy with the card.
          </p>

          <h2> What is the flex card from Medicare? </h2>

          <p>
            The Medicare flex card is a benefit offered by some private
            insurance companies that provide
            <ArticleLink to="/">Medicare Advantage</ArticleLink> plans. This
            card comes preloaded with a set amount of money that you can use to
            pay for healthcare costs. A few health plans may allow you to buy
            approved groceries and pay for utilities with your flex card.
          </p>

          <p>
            The flex card is not issued by Medicare. So if you're enrolled in
            original Medicare, this card will not be available to you.
          </p>

          <p>
            Insurance carriers offer the flex card as an incentive to get
            enrollees to sign up for their Medicare Advantage plans. The
            availability of the card and the amount of money you get vary widely
            by health insurance carrier, the plan you choose, and where you
            live.
          </p>

          <p>
            For example, a health plan may offer the Medicare flex card for
            people who live in specific states. The benefits may even vary by
            counties in the same state. A health plan
            <ArticleLink to="/"
              >may provide an allowance of $200 per quarter</ArticleLink
            >, while another company's flex card may include more funds.
          </p>

          <h2> How to use the Medicare flex card </h2>

          <p>
            Your Medicare flex card
            <ArticleLink to="/">works like a prepaid debit card</ArticleLink>.
            But before using it, you may have to:
          </p>

          <ul>
            <li>
              <strong>Activate your card.</strong>
              Some health plans require that you activate the card before use.
              Others may ask you to set up a pin for added security.
            </li>
            <li>
              <strong>Sign up for an online account.</strong>
              An online registration can help you manage your card and track
              available funds. But not all health plans offer this feature.
            </li>
            <li>
              <strong>Verify eligible items.</strong>
              You'll need to check what expenses qualify. This information is
              available from your health insurance carrier.
            </li>
            <li>
              <strong>Use your card.</strong>
              If you're purchasing approved
              <ArticleLink to="/">over-the-counter</ArticleLink> (OTC) items or
              paying for a prescription
              <ArticleLink to="">copay</ArticleLink> at the store, you'll swipe
              the card like any other debit card. You may also order eligible
              items online, by mail, or by phone.
            </li>
          </ul>

          <p>
            The amount you spend is instantly withdrawn from your account. If
            your purchase exceeds the available balance, you will be responsible
            for paying the difference.
          </p>

          <p>
            Depending on your plan, your spending allowance can be used to pay
            for approved out-of-pocket expenses like:
          </p>

          <ul>
            <li> OTC health items </li>
            <li> Prescription medication </li>
            <li> Office visit copays </li>
            <li> <ArticleLink to="/">Deductibles</ArticleLink> </li>
            <li> <ArticleLink to="/">Dental care</ArticleLink> </li>
            <li> <ArticleLink to="/">Vision care</ArticleLink> </li>
            <li> Hearing care </li>
            <li> Online purchases </li>
            <li> <ArticleLink to="/">Medical equipment</ArticleLink> </li>
          </ul>

          <p>
            Some Medicare Advantage plans offer additional benefits. For
            example,
            <ArticleLink to="/">Anthem's Benefits Prepaid Card</ArticleLink> may
            give you an allowance to pay for approved grocery items, service dog
            support products, and utilities. But there's a limit on how many
            extra benefits you can choose, and these services may have to be
            verified as <ArticleLink to="/">medically necessary</ArticleLink> by
            a healthcare provider.
          </p>

          <p>
            Keep in mind that you can't use your Medicare flex card at any
            store. Most health plans will have a list of participating
            retailers, like pharmacies and providers, where you can spend the
            funds.
          </p>

          <h2> Who is eligible for the Medicare flex card? </h2>

          <p>
            You must be enrolled in a qualifying Medicare Advantage plan to gain
            access to the Medicare flex card. Original Medicare beneficiaries
            not enrolled in a Medicare Advantage plan are not eligible for the
            flex card.
          </p>

          <p>
            But be mindful that not all Medicare Advantage plans offer the
            program. And even if you're enrolled in a Medicare Advantage plan
            that offers a flex card, it may not be available to you. Eligibility
            is determined by the insurance carrier, not by Medicare.
          </p>

          <p>
            To get the flex card, look for
            <ArticleLink to="/">available Medicare Advantage plans</ArticleLink>
            in your area. Then, check to see if they offer the program and if
            you qualify. If you want to switch plans to another to take
            advantage of the flex card for seniors,
            <ArticleLink to="/">Medicare Advantage open enrollment</ArticleLink>
            takes place every year from January 1 through March 31.
          </p>

          <h2> Are flex cards available with Medicare supplement plans? </h2>

          <p>
            Medicare flex cards are not available with
            <ArticleLink to="/">Medicare supplement plans (Medigap)</ArticleLink
            >. Medigap plans generally offset the out-of-pocket costs not
            covered by original Medicare and don't offer the same extra benefits
            as Medicare Advantage plans.
          </p>

          <p>
            Currently, Medicare flex cards are a benefit of Medicare Advantage
            plans only. It's important to note that you cannot be enrolled in a
            Medicare Advantage and a Medigap plan at the same time.
          </p>

          <h2> Medicare flex card benefits for seniors </h2>

          <p>
            Here's a look at flex card benefits and how they can vary across
            providers.
          </p>

          <h2> How much money is on the flex card for seniors? </h2>

          <p>
            Each plan sets its flex card allowance amount and spending rules.
            The amount of money available on the card is based on several
            factors, such as:
          </p>

          <ul>
            <li> The insurance carrier </li>
            <li> The Medicare Advantage plan </li>
            <li> Where you live </li>
            <li> Whether you have special needs or a chronic illness </li>
            <li>
              Whether you qualify for both Medicare and
              <ArticleLink to="/">Medicaid</ArticleLink>
            </li>
          </ul>

          <p>
            Generally, benefits start at a few hundred dollars. Below are some
            Medicare Advantage plans that offer flex cards and a few items that
            are covered:
          </p>

          <table>
            <thead>
              <tr>
                <th> Medicare Advantage carrier </th>
                <th> Amount on card </th>
                <th> Approved items </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> <ArticleLink to="/">Humana</ArticleLink> </td>
                <td> $250+ per year </td>
                <td>
                  Out-of-pocket costs for vision, dental, or hearing services
                </td>
              </tr>
              <tr>
                <td>
                  <ArticleLink to="/"
                    >Blue Cross Blue Shield of Louisiana</ArticleLink
                  >
                </td>
                <td> Varies </td>
                <td>
                  $500 for prescription hearing aids
                  <br />
                  <br />
                  $225 for eye glasses and contacts
                  <br />
                  <br />
                  $200 for OTC supplies for the year
                </td>
              </tr>
              <tr>
                <td> <ArticleLink to="/">WellCare</ArticleLink> </td>
                <td> $100 to $2,000 per year </td>
                <td> Dental, vision, and hearing costs </td>
              </tr>
              <tr>
                <td> <ArticleLink to="/">Anthem</ArticleLink> </td>
                <td> Up to $500 per year </td>
                <td>
                  Dental, vision, and hearing out-of-pocket costs or additional
                  services
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <ArticleLink to="/">Humana's flex allowance</ArticleLink> is usually
            available to members of some Florida plans. In addition, certain
            Medicare Advantage members may qualify for
            <ArticleLink to="/">Humana's Healthy Options Allowance</ArticleLink>
            of $35 to $275 per month to buy food and OTC products.
          </p>

          <p>
            The specific amount on
            <ArticleLink to="/">WellCare's Visa Flex Card</ArticleLink> depends
            on your plan and service area. Some WellCare Medicare Advantage
            plans also include a $25 or $50
            <ArticleLink to="/">healthy foods card</ArticleLink> for eligible
            people to buy foods.
          </p>

          <p>
            Some plans upload the full allowance upfront at the beginning of the
            year. Others opt for monthly and quarterly installments. Unused
            funds generally expire at the end of the plan year.
          </p>

          <h2> Is the Medicare flex card for seniors legitimate? </h2>

          <p>
            Medicare flex cards offered by well-known Medicare Advantage plans
            are legitimate. Unfortunately, scammers may target seniors with
            advertisements containing misleading information. Some dishonest
            commercials may instruct you to call a phone number or go to a
            website where scammers can obtain your personal information to
            commit fraud.
          </p>

          <p> Here are some red flags to watch out for: </p>

          <ul>
            <li>
              <strong>Claims that flex cards are a government program:</strong>
              Medicare — which is run by the government — does not offer flex
              cards.
            </li>
            <li>
              <strong>Claims that flex cards are “free money”:</strong>
              While these cards offer extra benefits, your insurance premiums
              may be higher.
            </li>
            <li>
              <strong>Unsolicited phone calls:</strong>
              Reputable insurance companies generally don't call, text, or email
              you unless you contact them first.
            </li>
          </ul>

          <p>
            If you believe you have been the victim of a Medicare flex card
            scam, report it to the government by calling
            <ArticleLink to="/">1-800-MEDICARE (1-800-633-4227)</ArticleLink>.
          </p>

          <h2> Is the Medicare flex card worth it? </h2>

          <p>
            Before enrolling in a Medicare Advantage plan that offers the flex
            card, consider the plan's overall benefits. Sometimes the added
            benefits you get with the flex card may not be worth the tradeoffs.
            For example, ask these questions:
          </p>

          <ul>
            <li> Does the insurance company have a good track record? </li>
            <li> Are my current healthcare providers in the network? </li>
            <li> Are my medications covered in the plan's drug formulary? </li>
            <li>
              Will my monthly premiums, deductibles, and copays be more?
            </li>
            <li> How much will the flex card help me save? </li>
            <li> What are the restrictions on the flex card? </li>
          </ul>

          <p>
            Look for alternatives, like original Medicare or Medigap, to see
            which option best fits your needs.
          </p>

          <ArticleInfoCard>
            <h2> The bottom line </h2>

            <p>
              The Medicare flex card for seniors can help you manage your
              healthcare and other out-of-pocket costs. This program is
              available through a few Medicare Advantage plans, and the benefits
              vary widely. Getting a flex card may often mean signing up for a
              new plan.
            </p>

            <p>
              While the flex card can make it easier to cover some expenses,
              enrolling in a Medicare Advantage plan that fits your healthcare
              coverage needs is important. Talk to a trusted advisor or licensed
              insurance agent to help you make the best decision.
            </p>
          </ArticleInfoCard>

          <h2> References </h2>

          <p class="text-sm">
            Anthem Blue Cross Blue Shield. (n.d.).
            <ArticleLink to="/">Medicare Advantage essential extras</ArticleLink
            >.
            <br />
            <br />
            Anthem Blue Cross Blue Shield. (n.d.).
            <ArticleLink to="/">What is a flex card for Medicare?</ArticleLink>
          </p>

          <ArticleRate />

          <ArticleInfoCard>
            <h2> Subscribe and save. </h2>

            <p>
              Get prescription saving tips and more from AllowanceCards.com
              Health. Enter your email to sign up.
            </p>

            <ClickToCall :text="phone.formatted" tty />

            <p>
              By signing up, I agree to AllowanceCards.com's Terms and Privacy
              Policy, and to receive marketing messages from AllowanceCards.com.
            </p>
          </ArticleInfoCard>

          <ArticleRelatedArticles :articles="[]" />
        </Article>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>
  </Layout>
</template>
