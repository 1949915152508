<script setup lang="ts">
const { value: olderThan63 } = useField('olderThan63')
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red-500 text-center">
    Take this quiz - see if you qualify
  </div>

  <div class="text-3xl sm:text-4xl text-center"> Are you older than 63? </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="olderThan63 = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="olderThan63 = false"
    >
      No
    </Button>
  </div>
</template>
