import { computed } from 'vue'
import { DateTime } from 'luxon'

export const isOutsideAEP = computed(() => {
  const today = DateTime.local()
  const startAEP = DateTime.local(today.year, 10, 15)
  const endAEP = DateTime.local(today.year, 12, 7).endOf('day')

  return today < startAEP || today > endAEP
})
