<script setup lang="ts">
useHead({
  title: `Affordable Healthcare - ${brand.name}`,
})
</script>

<template>
  <Layout hideHeader footer="aca" class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="affordableHealthcare3"
            class="!max-w-3xl py-6 px-4 sm:px-6"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'groupHealth' },
              { id: 'income' },
              { id: 'medicareOrMedicaid' },
              {
                id: 'sorry',
                if: values?.haveMedicareOrMedicaid === true,
              },
              { id: 'us' },
              { id: 'loading' },
              { id: 'call', completed: true },
            ]"
            product="aca"
          />
        </UseForm>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["ayb"],
    "robots": "noindex"
  }
}
</route>
