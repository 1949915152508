<template>
  <div>
    <img src="../assets/emily-avatar.png" class="w-12 h-12" />
    <div class="text-sm text-gray-500 mt-2 mb-4">
      <div>
        Written by <span class="font-semibold text-gray-900"> Emily </span>
      </div>
      <div> Published on February 23, 2023 </div>
    </div>
    <ArticleSocialLinks />
  </div>
</template>
