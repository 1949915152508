<script setup lang="ts">
const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center text-red font-bold">
    Congratulations!
  </div>

  <div class="text-2xl sm:text-3xl text-center">
    You may qualify for a $0/mo ACA Health Insurance plan!
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Click Below To Claim Your Benefits Now!
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-lg sm:text-xl font-medium text-center">
    Act quickly!
    <br />
    This pre-approval expires in <span class="text-red"> {{ timer }} </span>
  </div>
</template>
