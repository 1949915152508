<script setup lang="ts">
const { value: haveMedicaid } = useField('haveMedicaid')
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red-500 text-center">
    Take this quiz - see if you qualify
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Are you enrolled in Medicaid?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicaid = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicaid = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicaid = null"
    >
      Skip
    </Button>
  </div>
</template>
