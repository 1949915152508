<script setup lang="ts">
const emit = defineEmits<{
  (e: 'done'): void
}>()

const props = defineProps<{
  items: {
    text: string
    icon: 'check' | 'x'
  }[]
  speed?: number
}>()

const showing = ref(1)
const typewriterDone = ref(false)

const handleTypewriterDone = () => {
  typewriterDone.value = true
  setTimeout(() => {
    showing.value++
    typewriterDone.value = false

    if (showing.value > props.items.length) {
      setTimeout(() => {
        emit('done')
      }, 500)
    }
  }, 800)
}
</script>

<template>
  <div
    class="max-w-xs mx-auto space-y-1"
    :style="{
      minHeight: `${items.length * 1.75 + (items.length - 1) * 0.25}rem`,
    }"
  >
    <div
      v-for="(item, index) in items.slice(0, showing)"
      :key="item.text"
      class="flex items-center"
    >
      <div class="flex-1 text-lg">
        <Typewriter
          :text="item.text"
          :speed="speed || 40"
          cursor=""
          @done="handleTypewriterDone"
        />
      </div>
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition-opacity delay-300 duration-500"
      >
        <div v-if="showing > index + 1 || typewriterDone">
          <div v-if="item.icon === 'x'" class="bg-red-200 rounded-full p-0.5">
            <Icon i-heroicons-solid:x class="text-red-700" />
          </div>
          <div v-else class="bg-green-200 rounded-full p-0.5">
            <Icon i-heroicons-solid:check class="text-green-700" />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
