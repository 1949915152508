<script setup lang="ts">
const cards = [
  {
    id: 1,
    name: 'Essence Healthcare',
    pills: ['HMO', 'PPO', 'D-SNP'],
    accentText: '28k members',
    foregroundColor: '#ffffff',
    backgroundColor: '#6368ff',
    ctaText: 'Learn More',
    ctaTarget: 'https://google.com',
  },
  {
    id: 2,
    name: 'Aetna - Medicare Advantage Plans',
    pills: ['Shop Online', 'Plans Start at $0/mo'],
    accentText: '853k members',
    foregroundColor: '#883b9c',
    backgroundColor: '#ffffff',
    ctaText: 'Learn More',
    ctaTarget: 'https://google.com',
  },
]
</script>

<template>
  <div class="text-xl sm:text-2xl text-center">
    We've matched you with 3 great options in New Jersey
  </div>

  <div class="space-y-4">
    <Card
      v-for="card in cards"
      :key="card.id"
      :name="card.name"
      :pills="card.pills"
      :accent-text="card.accentText"
      :foreground-color="card.foregroundColor"
      :background-color="card.backgroundColor"
      :cta-text="card.ctaText"
      :cta-target="card.ctaTarget"
    />
  </div>
</template>
