<script setup lang="ts">
const router = useRouter()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  router.push(`/affordable-healthcare-3/?state=${values.state}`)
})
</script>

<template>
  <form class="max-w-xl mx-auto grid gap-4" @submit="onSubmit">
    <Select
      name="state"
      initialValue="AL"
      :options="
        states.map((state) => ({ value: state.code, text: state.name }))
      "
    />
    <Button wide type="submit"> Shop Plans </Button>
  </form>
</template>
