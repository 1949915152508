<script setup lang="ts">
const router = useRouter()

const state = ref('US')

const zipCode = ref('')

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  state.value = ip.lookup?.stateName || ip.lookup?.stateCode || 'US'
})

const onClick = () => {
  router.push(`/search-4-benefits-2024/search?zip=${zipCode.value}`)
}

analytics.page({
  name: 'Compare Plans LP 1.0',
  category: 'LP',
})
</script>

<template>
  <Layout hideHeader footer="2024" class="bg-blue-100">
    <div
      class="container min-h-screen flex items-center justify-center overflow-hidden"
    >
      <div
        class="bg-white py-8 px-4 rounded-lg shadow-lg w-full space-y-4 md:flex md:items-center md:flex-col md:w-1/2"
      >
        <div>
          <h2 class="text-3xl md:text-4xl font-bold mb-2 text-blue-800">
            Compare
          </h2>
          <p class="text-gray-700">
            the nation's top Medicare plans. All in one place.
          </p>
        </div>
        <div class="h-96 w-full md:w-96 flex items-center justify-center">
          <div class="flex justify-center w-96 h-96 relative overflow-hidden">
            <div
              class="absolute rounded-full bg-blue-100 h-60 w-60 md:w-60 mx-auto flex justify-center"
            >
              <div
                class="text-blue-600 flex justify-center flex-col gap-4 pl-4 -pt-4"
              >
                <span class="text-3xl font-bold -mt-6">$0</span>
                <p class="text-xs max-w-8">MONTHLY PLAN PREMIUM</p>
              </div>
              <img
                src="../../assets/call-center.png"
                alt="Call center lady"
                class="h-40 w-40 -pr-8 mt-4"
              />
            </div>

            <div
              class="mb-6 rounded-lg shadow-lg bg-blue-200 p-4 -mt-6 absolute z-20 top-50 flex flex-col gap-4"
            >
              <label for="zipcode" class="block mb-2 text-sm text-gray-600">
                See 2024 Medicare Advantage plans in
                <span class="font-semibold"> {{ state || 'US' }} </span>:
              </label>
              <input
                type="text"
                id="zipcode"
                placeholder="ZIP Code"
                class="w-full p-2 border rounded-lg"
                v-model="zipCode"
              />
              <button
                class="w-full bg-blue-600 text-white p-3 rounded-lg font-semibold"
                @click="onClick"
              >
                See plans in my area
              </button>
            </div>
          </div>
        </div>

        <div class="text-center mt-4 text-sm">
          Or speak with an licensed insurance agent
          <br />
          <ClickToCall v-slot="{ handleClick }: any">
            <a
              :href="phone.uri"
              @click="handleClick"
              class="!text-blue-800 !font-bold text-xl"
            >
              {{ phone.formatted }}
            </a>
          </ClickToCall>
        </div>
      </div>
    </div>
  </Layout>
</template>
