<script setup lang="ts">
const show = ref(false)

const router = useRouter()
const route = useRoute()

enterExperiment('helplineFacebook')

if (exp.value?.helplineFacebook?.helpline) {
  router.replace({
    path: '/helpline',
    query: route.query,
  })
} else if (!import.meta.env.SSR) {
  show.value = true

  useHead({
    title: `Get a quote - ${brand.name}`,
  })
}
</script>

<template>
  <Layout v-if="show" hideHeader class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        American Seniors 65+ with Medicare Part A&B are Getting up to
        <span class="text-red"> $2,000 </span> Back on Their Social Security
        Checks
      </div>

      <img src="../../assets/money.jpg" class="w-[90%] sm:w-[400px] mx-auto" />

      <Form
        id="call4Giveback"
        class="!max-w-3xl"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'age' },
          { id: 'us' },
          { id: 'loading' },
          { id: 'call' },
        ]"
        product="medicare"
      />

      <Carriers />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "ayb", "bh"],
    "smid": "MULTIPLAN_EH2022FB0623v1_M"
  }
}
</route>
