<script setup lang="ts">
const open = ref(true)
</script>

<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div
      :class="[' border-b border-gray-300 flex items-center justify-between']"
      @click="open = !open"
    >
      <div>
        <div class="text-lg font-medium"> Important Disclaimers </div>
        <div class="text-gray-600"> (scroll within this box to view) </div>
      </div>
      <Icon
        i-heroicons-outline:chevron-down
        :class="[
          open && 'rotate-180',
          'text-gray-500 transition-transform duration-300',
        ]"
      />
    </div>
    <div
      :class="[
        open ? 'h-64' : 'h-0',
        'overflow-auto transition-all duration-300 pb-4',
      ]"
    >
      <div
        :class="[
          open ? 'opacity-100' : 'opacity-0',
          ' transition-all text-gray-600',
        ]"
      >
        {{ brand.url }} is not affiliated with or endorsed by the United States
        government or any State or Federal program or marketplace. This is a
        solicitation of insurance. By calling
        <ClickToCall v-slot="{ handleClick }">
          <a :href="phone.uri" @click="handleClick">
            {{ phone.formatted }}
          </a>
        </ClickToCall>
        you will be connected with a licensed insurance agent with a
        participating sales agency to discuss health insurance plans from a
        number of different insurance companies.
        <br />
        <br />

        Plans and products may vary and are not available in all states. Plan
        benefits may vary by coverage area and by plan category and may not be
        offered in all plans or in all states. Not all benefits listed may be
        available on all plans or in a single plan benefits package. Plans may
        require copayments, deductibles and/or coinsurance for these benefits.
        Plans and policies are subject to exclusions, limitations, reduction of
        benefits, and terms under which the policy may be continued in force or
        discontinued. For costs and complete coverage details, speak with your
        licensed insurance agent or the health plan directly.
        <br />
        <br />

        We can assist with initial eligibility screenings, but only the state
        agencies can determine final eligibility. The giving of financial
        information is optional and does not affect enrollment eligibility. This
        website may not display all data on Qualified Health Plans (QHPs) being
        offered in your state through the Health Insurance MarketplaceSM
        website. To see all available data on QHP options in your state, go to
        the Health Insurance MarketplaceSM website at HealthCare.gov.
        <br />
        <br />

        <FooterBenefits />

        By using this site, you acknowledge that you have read and agree to the
        <Link to="/tos">Terms of Service</Link>
        and
        <Link to="/privacy">Privacy Policy</Link>.
        <br />
        <br />

        <div class="sm:hidden">
          <div class="flex items-center space-x-6 my-2">
            <img src="../assets/bbb-badge.svg" width="97" height="37" />
            <img src="../assets/comodo-badge.svg" width="107" height="34" />
          </div>
          <br />
        </div>

        Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All rights
        reserved | 8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069

        <template v-if="smid">
          <br />
          <br />
          {{ smid }}
        </template>
      </div>
    </div>
  </div>
</template>
