<template>
  <div class="text-3xl sm:text-4xl text-center text-darkBlue">
    You qualify for this benefit
  </div>

  <ClickToCall>
    <template #text>
      <span class="text-2xl sm:text-4xl"> {{ phone.formatted }} </span>
      <br />
      <span class="text-lg sm:text-3xl font-normal">
        CLICK TO CALL (FREE)
      </span>
    </template>
  </ClickToCall>
</template>
