<script setup lang="ts">
useHead({
  title: 'Get a quote - Medicare10',
})
</script>

<template>
  <Layout header="simple" class="container flex flex-col">
    <div class="flex-1 py-16">
      <Form
        id="acaV1"
        submitHandler="leadgen"
        successPage="/aca/thank-you"
        failurePage="/aca/sorry"
        :steps="[
          { id: 'zipcode', skip: true },
          { id: 'healthInsurance' },
          { id: 'name', skip: true },
          { id: 'emailAndPhone' },
        ]"
        product="aca"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "ayb", "bh"],
    "robots": "noindex"
  }
}
</route>
