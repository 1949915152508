<script setup lang="ts">
const open = ref(false)

const links = [
  {
    text: 'Top Balance',
    path: '/go-4-benefits',
  },
  {
    text: 'Grocery',
    path: '/go-4-benefits',
  },
  {
    text: 'Over-the-Counter',
    path: '/go-4-benefits',
  },
  {
    text: 'Gas & Utilities',
    path: '/go-4-benefits',
  },
  {
    text: 'Health Services',
    path: '/go-4-benefits',
  },
  {
    text: 'Dental & Vision',
    path: '/go-4-benefits',
  },
]
</script>

<template>
  <div :class="[open && 'fixed inset-0 z-10 flex flex-col', 'bg-white shadow']">
    <slot name="banner" />
    <div class="container flex items-center py-4">
      <RouterLink to="/" class="mr-auto">
        <Logo />
      </RouterLink>
      <button @click="open = !open" class="flex p-2 -m-2">
        <Icon v-if="!open" i-heroicons-outline:menu class="text-gray-500" />
        <Icon v-else i-heroicons-outline:x class="text-gray-500" />
      </button>
    </div>
    <div v-if="open" class="flex-1 border-t border-gray-200">
      <div class="container py-4">
        <div>
          <RouterLink
            v-for="link in links"
            :key="link.text"
            :to="link.path"
            class="block hover:text-blue-600 active:text-blue-600 py-1"
          >
            {{ link.text }}
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
