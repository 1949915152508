<script setup lang="ts">
const phoneTextColor = computed(() => 'text-red')

let now = new Date()

// Add 30 minutes to the current time
const minutes = now.getMinutes()
const remainingMinutes = 30 - (minutes % 30)
now.setMinutes(minutes + remainingMinutes)

// Format the result as hh:mm AM/PM
const options: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
}
const time = now.toLocaleTimeString('en-US', options)
</script>

<template>
  <div class="bg-white shadow sticky top-0 z-20">
    <slot name="banner" />
    <div class="container flex items-center space-x-4 py-2">
      <RouterLink to="/" class="mr-auto py-2">
        <LogoSmall class="sm:hidden" />
        <Logo class="hidden sm:block" />
      </RouterLink>
      <div class="hidden lg:block text-xl text-darkBlue">
        Need a quote? <span class="font-semibold"> Call toll free: </span>
      </div>
      <div
        class="text-right text-sm text-gray-500 max-w-sm md:max-w-lg -mt-[10px]"
      >
        <div class="w-full text-center"> Call before {{ time }} </div>
        <div :class="['text-2xl leading-7', phoneTextColor]">
          <ClickToCall
            :props="{ ctcLocation: 'header' }"
            v-slot="{ handleClick }: any"
          >
            <a
              class="bg-[#00B400] active:bg-[#008900] border-[#00B400] text-white py-2 px-12 rounded-full text-base"
              :href="phone.uri"
              @click="handleClick"
            >
              Call Now
            </a>
          </ClickToCall>
        </div>
      </div>
    </div>
  </div>
</template>
