<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `Affordable Healthcare - ${brand.name}`,
})

const date = ref()

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
})

const route = useRoute()
const clickTyp = ref(false)

provide('clickTyp', clickTyp)

onMounted(() => {
  clickTyp.value = route.query.typ === 'clicks'
})

const lowBid = ref(false)

provide('lowBid', lowBid)
</script>

<template>
  <Layout hideHeader footer="aca" class="container">
    <div class="max-w-3xl mx-auto space-y-6 pt-4 pb-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <div class="text-xl sm:text-3xl font-bold text-center">
        Thanks to the US Government, Americans With Incomes Below
        <span class="text-red">$60,000</span> May Qualify For Plans As Low As
        <span class="text-red">$0/Month</span>, Thanks To The Health Insurance
        Assistance Program
      </div>

      <img
        src="../../assets/health-insurance-2.png"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="affordableHealthcare2"
            class="!max-w-3xl py-6 px-4 sm:px-6"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'groupHealth' },
              { id: 'income' },
              { id: 'medicareOrMedicaid' },
              {
                id: 'sorry',
                if: values?.haveMedicareOrMedicaid === true,
              },
              { id: 'us' },
              { id: 'loading' },
              { id: 'call', completed: true },
            ]"
            product="aca"
          />
        </UseForm>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "ayb"],
    "robots": "noindex"
  }
}
</route>
