<script setup lang="ts">
const benefits = [
  {
    value: 'giveback',
    text: 'Giveback',
  },
  {
    value: 'otc',
    text: 'OTC',
  },
  {
    value: 'dental',
    text: 'Dental',
  },
  {
    value: 'pdp',
    text: 'PDP',
  },
  {
    value: 'hearing',
    text: 'Hearing',
  },
  {
    value: 'vision',
    text: 'Vision',
  },
]

type Response = {
  planCount: number
  minAmount?: number | null
  maxAmount?: number | null
  avg?: number | null
  plans: any[]
}

const input = ref()
const data = ref<Response | null>(null)

const { handleSubmit, isSubmitting } = useForm()

const onSubmit = handleSubmit(async (values) => {
  try {
    const url = `https://website-api-65sjg55l7q-uc.a.run.app/benefits/summary?zip_code=${values.zipcode}&benefit=${values.benefit}&include_plans=true`
    const response = await axios.get(url)

    const summary = response.data.summary

    input.value = values
    data.value = {
      planCount: summary.planCount,
      minAmount: summary.min,
      maxAmount: summary.max,
      avg: summary.avg,
      plans: summary.summary,
    }
  } catch (error) {
    alert('An error occurred.')
  }
})

analytics.page({
  name: 'Location Plans',
  category: 'LP',
})
</script>

<template>
  <Layout :header="brand.id === 'bridge' ? 'simple5' : undefined" footer="bh">
    <div class="container py-16">
      <form v-if="!data" @submit="onSubmit" class="max-w-xl mx-auto space-y-6">
        <h1 class="text-3xl font-medium">
          Confirm
          {{
            benefits.some((benefit) => benefit.value === query.benefit)
              ? query.benefit!
              : 'giveback'
          }}
          benefit is available in your area
        </h1>

        <Select
          name="benefit"
          label="Benefit"
          :initialValue="
            benefits.some((benefit) => benefit.value === query.benefit)
              ? query.benefit!
              : 'giveback'
          "
          class="hidden"
          :options="benefits"
        />

        <Field
          type="zip"
          name="zipcode"
          label="ZIP code"
          rules="required"
          :initialValue="query.zip"
        />

        <Button wide size="sm" type="submit" :loading="isSubmitting">
          Search
        </Button>
      </form>

      <div v-else class="space-y-6">
        <h1 class="text-3xl font-medium"> Benefit details </h1>

        <div>
          <div> Benefit: {{ input.benefit }} </div>
          <div> ZIP code: {{ input.zipcode }} </div>
          <div> Plan count: {{ data.planCount }} </div>
        </div>

        <div v-if="data.planCount" class="overflow-x-auto">
          <table
            class="bg-white divide-y divide-gray-200 border border-gray-200"
          >
            <thead>
              <tr>
                <th
                  v-for="key in _.sortBy(Object.keys(data.plans[0]))"
                  :key="key"
                  class="whitespace-nowrap py-4 px-3 font-medium text-left"
                >
                  {{ key }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="plan in data.plans">
                <td
                  v-for="key in _.sortBy(Object.keys(data.plans[0]))"
                  :key="key"
                  class="whitespace-nowrap py-4 px-3 text-gray-500"
                >
                  {{ plan[key] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "robots": "noindex"
  }
}
</route>
