<script setup lang="ts">
useHead({
  title: `Call ${brand.name} for Medicare quotes`,
})
</script>

<template>
  <Layout hideHeader class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        People with Medicare A&B are getting
        <span class="text-red"> extra help </span> with their Original Medicare
        <span class="text-red"> costs </span> such as: deductibles, coinsurance
        and hospital bills thanks to new 2023 Medigap plans
      </div>

      <img
        src="../../../assets/call-4-extra-benefits.png"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <UseForm v-slot="{ values }">
        <Form
          id="medigap"
          class="!max-w-3xl"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'medicareCard' },
            { id: 'loading' },
            { id: 'callMs', if: values?.differentMedicareCard },
            { id: 'call' },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "ayb"],
    "robots": "noindex",
    "smid": "MULTI-PLAN_EHWEB092203_M"
  }
}
</route>
