type Options = {
  condition: () => boolean
  onResult: (lowBid: boolean) => void
}
type DemandCheckResponse = {
  ctc: {
    ev: number
    n: number
  }
}
export const useDemandCheck = (options: Options) => {
  const afid = computed(() => {
    return typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
      ? phoneConfig.value.afid
      : undefined
  })

  const isUnmounted = ref(false)

  onUnmounted(() => {
    isUnmounted.value = true
  })

  onMounted(async () => {
    if (placementId.value && options.condition()) {
      try {
        const url =
          import.meta.env.VITE_WEBSITE_API_URL +
          '/mediaalpha/call-transfers-check/v2'

        const res = await axios.post(url, {
          data: {
            zip: ip.lookup?.postalCode,
            state: ip.lookup?.stateCode,
          },
          ip: ip.lookup?.value,
          local_hour: new Date().getHours(),
          max: 1,
          call_type: 'Inbound',
          transfer_type: 'cold',
          placement_id: placementId.value,
          ua: navigator.userAgent,
          url: location.href,
          version: 17,
          sub_1: getSessionEnhanced().query.afid || afid.value,
          sub_2: segment.anonymousId,
          sub_3: getSession().id,
          source: [
            getSessionEnhanced().query.afid || afid.value || 'null',
            query.utm_campaign || 'null',
            query.adgroupid || 'null',
            query.publisher_id || 'null',
            query.publisher || 'null',
            query.channel || 'null',
            'null',
          ],
        })

        if (isUnmounted.value) return

        const data: DemandCheckResponse = res.data
        const lowBid =
          typeof data === 'object' &&
          data !== null &&
          'ctc' in data &&
          typeof data.ctc === 'object' &&
          data.ctc !== null &&
          'ev' in data.ctc &&
          typeof data.ctc.ev === 'number'
            ? data.ctc.ev < 10
            : false

        analytics.track('lowBidResult', {
          isLowBid: lowBid,
          maResponse: data,
        })

        options.onResult(lowBid)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  })
}
