<script setup lang="ts">
useHead({
  title: 'Sorry - Activate Your Benefits',
})

analytics.page({
  name: 'ACA Sorry 1.0',
  category: 'Error',
  product: 'aca',
})
</script>

<template>
  <Layout header="simple">
    <div class="bg-darkBlue text-white">
      <div class="container py-16">
        <h1 class="text-5xl sm:text-6xl"> Sorry, </h1>
        <div class="mt-6 mb-12 text-2xl text-white text-opacity-75">
          There was a problem with your submission. To speak to an agent now,
          please call our helpline.
        </div>
        <ClickToCall v-slot="{ handleClick }: any">
          <Button :to="phone.uri" @click="handleClick">
            helpline: {{ phone.formatted }}
          </Button>
        </ClickToCall>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "ayb", "bh"],
    "robots": "noindex"
  }
}
</route>
