<script setup lang="ts">
useHead({
  title: `Get a quote - ${brand.name}`,
})
</script>

<template>
  <Layout hideHeader footer="aca" class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-16">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Thanks to the US Government, Americans With Incomes Below
        <span class="text-red">$50,000</span> May Qualify For Plans As Low As
        <span class="text-red">$0/Month</span>, Thanks To The Health Insurance
        Assistance Program
      </div>

      <img
        src="../../../assets/health-insurance-2.png"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <Form
        id="healthInsurance2"
        class="!max-w-3xl"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'income' },
          { id: 'insured' },
          { id: 'medicaid' },
          { id: 'loading' },
          { id: 'call' },
        ]"
        product="aca"
      />

      <Carriers />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["ayb"],
    "robots": "noindex"
  }
}
</route>
