<script setup lang="ts">
import Typewriter from 'typewriter-effect/dist/core'

const emit = defineEmits<{
  (e: 'done'): void
}>()

const props = defineProps<{
  text: string
  speed?: number
  cursor?: string
}>()

const el = ref<HTMLDivElement | null>(null)

let typewriterInstance: any

onMounted(() => {
  typewriterInstance = new Typewriter(el.value, {
    cursor: props.cursor,
  })
  typewriterInstance
    .changeDelay(props.speed ?? 15)
    .typeString(props.text)
    .start()
    .callFunction(() => {
      emit('done')
    })
})

onBeforeUnmount(() => {
  typewriterInstance?.stop()
})
</script>

<template>
  <div ref="el"></div>
</template>
