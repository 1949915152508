type State = {
  code: string
  name: string
  medicaid: string
  minOtc: number | null
  maxOtc: number | null
  minOtcLtd: number | null
  minGiveback: number | null
  maxGiveback: number | null
  top5Otc: number | null
  minGivebackLtd: number | null
  maxFipsMinOtcLtdFips: string | null
  maxFipsMinOtcLtdAmount: number | null
  maxFipsMinGivebackLtdFips: string | null
  maxFipsMinOtcLtdCountyName: string | null
  maxFipsMinGivebackLtdAmount: number | null
  maxFipsMinGivebackLtdCountyName: string | null
}

export const states: State[] = [
  {
    code: 'AK',
    name: 'Alaska',
    maxOtc: 180,
    minOtc: 180,
    top5Otc: null,
    medicaid: 'DenaliCare',
    minOtcLtd: 180,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 10,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: null,
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: null,
    maxFipsMinGivebackLtdCountyName: null,
  },
  {
    code: 'AL',
    name: 'Alabama',
    maxOtc: 2400,
    minOtc: 100,
    top5Otc: 2100,
    medicaid: 'Alabama Medicaid',
    minOtcLtd: 816,
    maxGiveback: 164.9,
    minGiveback: 6,
    minGivebackLtd: 6,
    maxFipsMinOtcLtdFips: '01117',
    maxFipsMinOtcLtdAmount: 1296,
    maxFipsMinGivebackLtdFips: '01065',
    maxFipsMinOtcLtdCountyName: 'Shelby',
    maxFipsMinGivebackLtdAmount: 6,
    maxFipsMinGivebackLtdCountyName: 'Hale',
  },
  {
    code: 'AR',
    name: 'Arkansas',
    maxOtc: 2520,
    minOtc: 50,
    top5Otc: 2256,
    medicaid: 'Arkansas Medicaid',
    minOtcLtd: 600,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '05135',
    maxFipsMinOtcLtdAmount: 696,
    maxFipsMinGivebackLtdFips: '29201',
    maxFipsMinOtcLtdCountyName: 'Sharp',
    maxFipsMinGivebackLtdAmount: 126,
    maxFipsMinGivebackLtdCountyName: 'Scott',
  },
  {
    code: 'AZ',
    name: 'Arizona',
    maxOtc: 3264,
    minOtc: 100,
    top5Otc: 1620,
    medicaid: 'AHCCCS',
    minOtcLtd: 1884,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 30,
    maxFipsMinOtcLtdFips: '04012',
    maxFipsMinOtcLtdAmount: 1884,
    maxFipsMinGivebackLtdFips: '35041',
    maxFipsMinOtcLtdCountyName: 'La Paz',
    maxFipsMinGivebackLtdAmount: 59,
    maxFipsMinGivebackLtdCountyName: 'Roosevelt',
  },
  {
    code: 'CA',
    name: 'California',
    maxOtc: 2000,
    minOtc: 0,
    top5Otc: 1100,
    medicaid: 'Medi-Cal',
    minOtcLtd: 600,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '06111',
    maxFipsMinOtcLtdAmount: 600,
    maxFipsMinGivebackLtdFips: '06031',
    maxFipsMinOtcLtdCountyName: 'Ventura',
    maxFipsMinGivebackLtdAmount: 75,
    maxFipsMinGivebackLtdCountyName: 'Kings',
  },
  {
    code: 'CO',
    name: 'Colorado',
    maxOtc: 2580,
    minOtc: 0,
    top5Otc: 1400,
    medicaid: 'Health First Colorado',
    minOtcLtd: 840,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '08009',
    maxFipsMinOtcLtdAmount: 2220,
    maxFipsMinGivebackLtdFips: '04001',
    maxFipsMinOtcLtdCountyName: 'Baca',
    maxFipsMinGivebackLtdAmount: 75,
    maxFipsMinGivebackLtdCountyName: 'Apache',
  },
  {
    code: 'CT',
    name: 'Connecticut',
    maxOtc: 1860,
    minOtc: 0,
    top5Otc: 1200,
    medicaid: 'HUSKY Health',
    minOtcLtd: 90,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 40,
    maxFipsMinOtcLtdFips: '09015',
    maxFipsMinOtcLtdAmount: 576,
    maxFipsMinGivebackLtdFips: '33013',
    maxFipsMinOtcLtdCountyName: 'Windham',
    maxFipsMinGivebackLtdAmount: 60,
    maxFipsMinGivebackLtdCountyName: 'Merrimack',
  },
  {
    code: 'DC',
    name: 'Washington D.C.',
    maxOtc: 2508,
    minOtc: 120,
    top5Otc: 1008,
    medicaid: 'Healthy Families',
    minOtcLtd: 1008,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 55,
    maxFipsMinOtcLtdFips: '11001',
    maxFipsMinOtcLtdAmount: 1008,
    maxFipsMinGivebackLtdFips: '24027',
    maxFipsMinOtcLtdCountyName: 'District of Columbia',
    maxFipsMinGivebackLtdAmount: 55,
    maxFipsMinGivebackLtdCountyName: 'Howard',
  },
  {
    code: 'DE',
    name: 'Delaware',
    maxOtc: 2400,
    minOtc: 100,
    top5Otc: 1800,
    medicaid: 'Diamond State Health Plan (Plus)',
    minOtcLtd: 768,
    maxGiveback: 164.9,
    minGiveback: 8,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '10001',
    maxFipsMinOtcLtdAmount: 768,
    maxFipsMinGivebackLtdFips: '51047',
    maxFipsMinOtcLtdCountyName: 'Kent',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Culpeper',
  },
  {
    code: 'FL',
    name: 'Florida',
    maxOtc: 3960,
    minOtc: 50,
    top5Otc: 3420,
    medicaid: 'Statewide Medicaid Managed Care Program (SMMC)',
    minOtcLtd: 250,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '12063',
    maxFipsMinOtcLtdAmount: 516,
    maxFipsMinGivebackLtdFips: '12011',
    maxFipsMinOtcLtdCountyName: 'Jackson',
    maxFipsMinGivebackLtdAmount: 50,
    maxFipsMinGivebackLtdCountyName: 'Broward',
  },
  {
    code: 'GA',
    name: 'Georgia',
    maxOtc: 3000,
    minOtc: 0,
    top5Otc: 2280,
    medicaid: 'Georgia Medicaid',
    minOtcLtd: 500,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 30,
    maxFipsMinOtcLtdFips: '13021',
    maxFipsMinOtcLtdAmount: 500,
    maxFipsMinGivebackLtdFips: '13011',
    maxFipsMinOtcLtdCountyName: 'Bibb',
    maxFipsMinGivebackLtdAmount: 30,
    maxFipsMinGivebackLtdCountyName: 'Banks',
  },
  {
    code: 'HI',
    name: 'Hawaii',
    maxOtc: 1548,
    minOtc: 60,
    top5Otc: 840,
    medicaid: 'MedQuest',
    minOtcLtd: 528,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 40,
    maxFipsMinOtcLtdFips: '15003',
    maxFipsMinOtcLtdAmount: 528,
    maxFipsMinGivebackLtdFips: '15007',
    maxFipsMinOtcLtdCountyName: 'Honolulu',
    maxFipsMinGivebackLtdAmount: 50,
    maxFipsMinGivebackLtdCountyName: 'Kauai',
  },
  {
    code: 'IA',
    name: 'Iowa',
    maxOtc: 2592,
    minOtc: 40,
    top5Otc: 1200,
    medicaid: 'IA Health Link',
    minOtcLtd: 2040,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 70,
    maxFipsMinOtcLtdFips: '19001',
    maxFipsMinOtcLtdAmount: 2592,
    maxFipsMinGivebackLtdFips: '31011',
    maxFipsMinOtcLtdCountyName: 'Adair',
    maxFipsMinGivebackLtdAmount: 125,
    maxFipsMinGivebackLtdCountyName: 'Boone',
  },
  {
    code: 'ID',
    name: 'Idaho',
    maxOtc: 2340,
    minOtc: 80,
    top5Otc: 800,
    medicaid: 'Idaho Medicaid',
    minOtcLtd: 80,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: '30065',
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Musselshell',
  },
  {
    code: 'IL',
    name: 'Illinois',
    maxOtc: 2220,
    minOtc: 25,
    top5Otc: 1932,
    medicaid: 'Medical Assistance Program',
    minOtcLtd: 25,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 15,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: '29031',
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: 110,
    maxFipsMinGivebackLtdCountyName: 'Cape Girardeau',
  },
  {
    code: 'IN',
    name: 'Indiana',
    maxOtc: 3048,
    minOtc: 50,
    top5Otc: 3000,
    medicaid: 'Hoosier Healthwise',
    minOtcLtd: 1200,
    maxGiveback: 164.9,
    minGiveback: 9,
    minGivebackLtd: 9,
    maxFipsMinOtcLtdFips: '18177',
    maxFipsMinOtcLtdAmount: 1200,
    maxFipsMinGivebackLtdFips: '21037',
    maxFipsMinOtcLtdCountyName: 'Wayne',
    maxFipsMinGivebackLtdAmount: 102,
    maxFipsMinGivebackLtdCountyName: 'Campbell',
  },
  {
    code: 'KS',
    name: 'Kansas',
    maxOtc: 3000,
    minOtc: 25,
    top5Otc: 2928,
    medicaid: 'KanCare Medical Assistance Program',
    minOtcLtd: 2928,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '20133',
    maxFipsMinOtcLtdAmount: 2928,
    maxFipsMinGivebackLtdFips: '19147',
    maxFipsMinOtcLtdCountyName: 'Neosho',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Palo Alto',
  },
  {
    code: 'KY',
    name: 'Kentucky',
    maxOtc: 2400,
    minOtc: 50,
    top5Otc: 2088,
    medicaid: 'Kentucky Medicaid',
    minOtcLtd: 816,
    maxGiveback: 164.9,
    minGiveback: 9,
    minGivebackLtd: 9,
    maxFipsMinOtcLtdFips: '21121',
    maxFipsMinOtcLtdAmount: 816,
    maxFipsMinGivebackLtdFips: '18165',
    maxFipsMinOtcLtdCountyName: 'Knox',
    maxFipsMinGivebackLtdAmount: 102,
    maxFipsMinGivebackLtdCountyName: 'Vermillion',
  },
  {
    code: 'LA',
    name: 'Louisiana',
    maxOtc: 3000,
    minOtc: 100,
    top5Otc: 2880,
    medicaid: 'Bayou Health',
    minOtcLtd: 300,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 14,
    maxFipsMinOtcLtdFips: '22125',
    maxFipsMinOtcLtdAmount: 800,
    maxFipsMinGivebackLtdFips: '22011',
    maxFipsMinOtcLtdCountyName: 'West Feliciana',
    maxFipsMinGivebackLtdAmount: 88,
    maxFipsMinGivebackLtdCountyName: 'Beauregard',
  },
  {
    code: 'MA',
    name: 'Massachusetts',
    maxOtc: 1900,
    minOtc: 120,
    top5Otc: 1340,
    medicaid: 'MassHealth',
    minOtcLtd: 1500,
    maxGiveback: 164.9,
    minGiveback: 2,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '25015',
    maxFipsMinOtcLtdAmount: 1500,
    maxFipsMinGivebackLtdFips: '33011',
    maxFipsMinOtcLtdCountyName: 'Hampshire',
    maxFipsMinGivebackLtdAmount: 60,
    maxFipsMinGivebackLtdCountyName: 'Hillsborough',
  },
  {
    code: 'MD',
    name: 'Maryland',
    maxOtc: 1560,
    minOtc: 100,
    top5Otc: 1200,
    medicaid: 'Medical Assistance',
    minOtcLtd: 720,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '24035',
    maxFipsMinOtcLtdAmount: 900,
    maxFipsMinGivebackLtdFips: '24037',
    maxFipsMinOtcLtdCountyName: 'Queen Annes',
    maxFipsMinGivebackLtdAmount: 55,
    maxFipsMinGivebackLtdCountyName: 'Saint Marys',
  },
  {
    code: 'ME',
    name: 'Maine',
    maxOtc: 1932,
    minOtc: 0,
    top5Otc: 1320,
    medicaid: 'MaineCare',
    minOtcLtd: 960,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '23009',
    maxFipsMinOtcLtdAmount: 1032,
    maxFipsMinGivebackLtdFips: '33009',
    maxFipsMinOtcLtdCountyName: 'Hancock',
    maxFipsMinGivebackLtdAmount: 60,
    maxFipsMinGivebackLtdCountyName: 'Grafton',
  },
  {
    code: 'MI',
    name: 'Michigan',
    maxOtc: 3000,
    minOtc: 100,
    top5Otc: 2160,
    medicaid: 'Medical Assistance or MA',
    minOtcLtd: 816,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 14,
    maxFipsMinOtcLtdFips: '26109',
    maxFipsMinOtcLtdAmount: 1440,
    maxFipsMinGivebackLtdFips: '26095',
    maxFipsMinOtcLtdCountyName: 'Menominee',
    maxFipsMinGivebackLtdAmount: 70,
    maxFipsMinGivebackLtdCountyName: 'Luce',
  },
  {
    code: 'MN',
    name: 'Minnesota',
    maxOtc: 2700,
    minOtc: 100,
    top5Otc: 800,
    medicaid: 'Medical Assistance (MA) / MinnesotaCare',
    minOtcLtd: 2160,
    maxGiveback: 164.9,
    minGiveback: 4,
    minGivebackLtd: 20,
    maxFipsMinOtcLtdFips: '27139',
    maxFipsMinOtcLtdAmount: 2700,
    maxFipsMinGivebackLtdFips: '46041',
    maxFipsMinOtcLtdCountyName: 'Scott',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Dewey',
  },
  {
    code: 'MO',
    name: 'Missouri',
    maxOtc: 3300,
    minOtc: 25,
    top5Otc: 2640,
    medicaid: 'MO HealthNet',
    minOtcLtd: 768,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '29129',
    maxFipsMinOtcLtdAmount: 768,
    maxFipsMinGivebackLtdFips: '05145',
    maxFipsMinOtcLtdCountyName: 'Mercer',
    maxFipsMinGivebackLtdAmount: 126,
    maxFipsMinGivebackLtdCountyName: 'White',
  },
  {
    code: 'MS',
    name: 'Mississippi',
    maxOtc: 3120,
    minOtc: 100,
    top5Otc: 2520,
    medicaid: 'Mississippi Coordinated Access Network (MississippiCAN)',
    minOtcLtd: 1092,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 75,
    maxFipsMinOtcLtdFips: '28073',
    maxFipsMinOtcLtdAmount: 1200,
    maxFipsMinGivebackLtdFips: '28105',
    maxFipsMinOtcLtdCountyName: 'Lamar',
    maxFipsMinGivebackLtdAmount: 76,
    maxFipsMinGivebackLtdCountyName: 'Oktibbeha',
  },
  {
    code: 'MT',
    name: 'Montana',
    maxOtc: 1644,
    minOtc: 100,
    top5Otc: 400,
    medicaid: 'Montana Medicaid',
    minOtcLtd: 900,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '30091',
    maxFipsMinOtcLtdAmount: 1644,
    maxFipsMinGivebackLtdFips: '41017',
    maxFipsMinOtcLtdCountyName: 'Sheridan',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Deschutes',
  },
  {
    code: 'NC',
    name: 'North Carolina',
    maxOtc: 4000,
    minOtc: 100,
    top5Otc: 3084,
    medicaid: 'Division of Health Benefits (DHB)',
    minOtcLtd: 500,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '37031',
    maxFipsMinOtcLtdAmount: 3612,
    maxFipsMinGivebackLtdFips: '51015',
    maxFipsMinOtcLtdCountyName: 'Carteret',
    maxFipsMinGivebackLtdAmount: 54,
    maxFipsMinGivebackLtdCountyName: 'Augusta',
  },
  {
    code: 'ND',
    name: 'North Dakota',
    maxOtc: 900,
    minOtc: 100,
    top5Otc: 400,
    medicaid: 'North Dakota Medicaid Expansion Program',
    minOtcLtd: 100,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 70,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: '38103',
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Wells',
  },
  {
    code: 'NE',
    name: 'Nebraska',
    maxOtc: 2580,
    minOtc: 40,
    top5Otc: 2244,
    medicaid: 'ACCESSNebraska',
    minOtcLtd: 744,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 70,
    maxFipsMinOtcLtdFips: '31125',
    maxFipsMinOtcLtdAmount: 2244,
    maxFipsMinGivebackLtdFips: '31035',
    maxFipsMinOtcLtdCountyName: 'Nance',
    maxFipsMinGivebackLtdAmount: 125,
    maxFipsMinGivebackLtdCountyName: 'Clay',
  },
  {
    code: 'NH',
    name: 'New Hampshire',
    maxOtc: 1160,
    minOtc: 100,
    top5Otc: 600,
    medicaid: 'New Hampshire Medicaid',
    minOtcLtd: 100,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 55,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: '23017',
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: 60,
    maxFipsMinGivebackLtdCountyName: 'Oxford',
  },
  {
    code: 'NJ',
    name: 'New Jersey',
    maxOtc: 3660,
    minOtc: 0,
    top5Otc: 1160,
    medicaid: 'New Jersey FamilyCare',
    minOtcLtd: 3480,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '34011',
    maxFipsMinOtcLtdAmount: 3480,
    maxFipsMinGivebackLtdFips: '42021',
    maxFipsMinOtcLtdCountyName: 'Cumberland',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Cambria',
  },
  {
    code: 'NM',
    name: 'New Mexico',
    maxOtc: 1260,
    minOtc: 100,
    top5Otc: 1120,
    medicaid: 'Centennial Care',
    minOtcLtd: 100,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 30,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: '35059',
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: 115,
    maxFipsMinGivebackLtdCountyName: 'Union',
  },
  {
    code: 'NV',
    name: 'Nevada',
    maxOtc: 2000,
    minOtc: 0,
    top5Otc: 1500,
    medicaid: 'Nevada Medicaid',
    minOtcLtd: 900,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '32031',
    maxFipsMinOtcLtdAmount: 900,
    maxFipsMinGivebackLtdFips: '32021',
    maxFipsMinOtcLtdCountyName: 'Washoe',
    maxFipsMinGivebackLtdAmount: 50,
    maxFipsMinGivebackLtdCountyName: 'Mineral',
  },
  {
    code: 'NY',
    name: 'New York',
    maxOtc: 3600,
    minOtc: 0,
    top5Otc: 2700,
    medicaid: 'Medicaid Managed Care',
    minOtcLtd: 684,
    maxGiveback: 164.9,
    minGiveback: 1.3,
    minGivebackLtd: 20,
    maxFipsMinOtcLtdFips: '36043',
    maxFipsMinOtcLtdAmount: 684,
    maxFipsMinGivebackLtdFips: '36109',
    maxFipsMinOtcLtdCountyName: 'Herkimer',
    maxFipsMinGivebackLtdAmount: 80,
    maxFipsMinGivebackLtdCountyName: 'Tompkins',
  },
  {
    code: 'OH',
    name: 'Ohio',
    maxOtc: 3000,
    minOtc: 60,
    top5Otc: 2580,
    medicaid: 'Ohio Medicaid',
    minOtcLtd: 924,
    maxGiveback: 164.9,
    minGiveback: 2,
    minGivebackLtd: 70,
    maxFipsMinOtcLtdFips: '39151',
    maxFipsMinOtcLtdAmount: 1092,
    maxFipsMinGivebackLtdFips: '21101',
    maxFipsMinOtcLtdCountyName: 'Stark',
    maxFipsMinGivebackLtdAmount: 102,
    maxFipsMinGivebackLtdCountyName: 'Henderson',
  },
  {
    code: 'OK',
    name: 'Oklahoma',
    maxOtc: 3000,
    minOtc: 50,
    top5Otc: 2292,
    medicaid: 'SoonerCare',
    minOtcLtd: 804,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 32,
    maxFipsMinOtcLtdFips: '40119',
    maxFipsMinOtcLtdAmount: 1200,
    maxFipsMinGivebackLtdFips: '19119',
    maxFipsMinOtcLtdCountyName: 'Payne',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Lyon',
  },
  {
    code: 'OR',
    name: 'Oregon',
    maxOtc: 1680,
    minOtc: 80,
    top5Otc: 1060,
    medicaid: 'Oregon Health Plan',
    minOtcLtd: 80,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 30,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: '41003',
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: 110,
    maxFipsMinGivebackLtdCountyName: 'Benton',
  },
  {
    code: 'PA',
    name: 'Pennsylvania',
    maxOtc: 3600,
    minOtc: 60,
    top5Otc: 2400,
    medicaid: 'Medical Assistance (MA)',
    minOtcLtd: 600,
    maxGiveback: 164.9,
    minGiveback: 1,
    minGivebackLtd: 10,
    maxFipsMinOtcLtdFips: '42105',
    maxFipsMinOtcLtdAmount: 600,
    maxFipsMinGivebackLtdFips: '42097',
    maxFipsMinOtcLtdCountyName: 'Potter',
    maxFipsMinGivebackLtdAmount: 70,
    maxFipsMinGivebackLtdCountyName: 'Northumberland',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    maxOtc: 3180,
    minOtc: 100,
    top5Otc: 1680,
    medicaid: 'Puerto Rico Medicaid',
    minOtcLtd: 480,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 10,
    maxFipsMinOtcLtdFips: '72003',
    maxFipsMinOtcLtdAmount: 480,
    maxFipsMinGivebackLtdFips: '72035',
    maxFipsMinOtcLtdCountyName: 'Aguada',
    maxFipsMinGivebackLtdAmount: 10,
    maxFipsMinGivebackLtdCountyName: 'Cayey',
  },
  {
    code: 'RI',
    name: 'Rhode Island',
    maxOtc: 3000,
    minOtc: 60,
    top5Otc: 1752,
    medicaid: 'RI Medical Assistance Program',
    minOtcLtd: 744,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '44005',
    maxFipsMinOtcLtdAmount: 744,
    maxFipsMinGivebackLtdFips: '44009',
    maxFipsMinOtcLtdCountyName: 'Newport',
    maxFipsMinGivebackLtdAmount: 60,
    maxFipsMinGivebackLtdCountyName: 'Washington',
  },
  {
    code: 'SC',
    name: 'South Carolina',
    maxOtc: 3420,
    minOtc: 50,
    top5Otc: 2220,
    medicaid: 'Healthy Connections',
    minOtcLtd: 500,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 30,
    maxFipsMinOtcLtdFips: '45073',
    maxFipsMinOtcLtdAmount: 500,
    maxFipsMinGivebackLtdFips: '13063',
    maxFipsMinOtcLtdCountyName: 'Oconee',
    maxFipsMinGivebackLtdAmount: 30,
    maxFipsMinGivebackLtdCountyName: 'Clayton',
  },
  {
    code: 'SD',
    name: 'South Dakota',
    maxOtc: 1620,
    minOtc: 100,
    top5Otc: 400,
    medicaid: 'South Dakota Medicaid',
    minOtcLtd: 500,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 70,
    maxFipsMinOtcLtdFips: '46041',
    maxFipsMinOtcLtdAmount: 1344,
    maxFipsMinGivebackLtdFips: '46105',
    maxFipsMinOtcLtdCountyName: 'Dewey',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Perkins',
  },
  {
    code: 'TN',
    name: 'Tennessee',
    maxOtc: 3708,
    minOtc: 60,
    top5Otc: 1692,
    medicaid: 'TennCare',
    minOtcLtd: 60,
    maxGiveback: 164.9,
    minGiveback: 6,
    minGivebackLtd: 6,
    maxFipsMinOtcLtdFips: '47107',
    maxFipsMinOtcLtdAmount: 60,
    maxFipsMinGivebackLtdFips: '51520',
    maxFipsMinOtcLtdCountyName: 'McMinn',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Bristol City',
  },
  {
    code: 'TX',
    name: 'Texas',
    maxOtc: 2400,
    minOtc: 0,
    top5Otc: 1800,
    medicaid: 'STAR+PLUS',
    minOtcLtd: 85,
    maxGiveback: 164.9,
    minGiveback: 7.4,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '48017',
    maxFipsMinOtcLtdAmount: 720,
    maxFipsMinGivebackLtdFips: '48235',
    maxFipsMinOtcLtdCountyName: 'Bailey',
    maxFipsMinGivebackLtdAmount: 125,
    maxFipsMinGivebackLtdCountyName: 'Irion',
  },
  {
    code: 'UT',
    name: 'Utah',
    maxOtc: 1680,
    minOtc: 60,
    top5Otc: 1440,
    medicaid: 'Utah Medicaid',
    minOtcLtd: 1044,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '49037',
    maxFipsMinOtcLtdAmount: 1440,
    maxFipsMinGivebackLtdFips: '16045',
    maxFipsMinOtcLtdCountyName: 'San Juan',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Gem',
  },
  {
    code: 'VA',
    name: 'Virginia',
    maxOtc: 4500,
    minOtc: 0,
    top5Otc: 3600,
    medicaid: 'Cardinal Care',
    minOtcLtd: 708,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 25,
    maxFipsMinOtcLtdFips: '51840',
    maxFipsMinOtcLtdAmount: 708,
    maxFipsMinGivebackLtdFips: '47151',
    maxFipsMinOtcLtdCountyName: 'Winchester City',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Scott',
  },
  {
    code: 'VI',
    name: 'Virgin Islands',
    maxOtc: 180,
    minOtc: 180,
    top5Otc: null,
    medicaid: 'Virgin Islands Medicaid',
    minOtcLtd: 180,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 10,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: null,
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: null,
    maxFipsMinGivebackLtdCountyName: null,
  },
  {
    code: 'VT',
    name: 'Vermont',
    maxOtc: 1200,
    minOtc: 100,
    top5Otc: 400,
    medicaid: 'Green Mountain Care',
    minOtcLtd: 100,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 55,
    maxFipsMinOtcLtdFips: null,
    maxFipsMinOtcLtdAmount: null,
    maxFipsMinGivebackLtdFips: '23019',
    maxFipsMinOtcLtdCountyName: null,
    maxFipsMinGivebackLtdAmount: 60,
    maxFipsMinGivebackLtdCountyName: 'Penobscot',
  },
  {
    code: 'WA',
    name: 'Washington',
    maxOtc: 2220,
    minOtc: 0,
    top5Otc: 1500,
    medicaid: 'Apple Health',
    minOtcLtd: 768,
    maxGiveback: 164.9,
    minGiveback: 0.1,
    minGivebackLtd: 30,
    maxFipsMinOtcLtdFips: '53021',
    maxFipsMinOtcLtdAmount: 768,
    maxFipsMinGivebackLtdFips: '30013',
    maxFipsMinOtcLtdCountyName: 'Franklin',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Cascade',
  },
  {
    code: 'WI',
    name: 'Wisconsin',
    maxOtc: 3000,
    minOtc: 60,
    top5Otc: 2088,
    medicaid: 'ForwardHealth / BadgerCare',
    minOtcLtd: 888,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 15,
    maxFipsMinOtcLtdFips: '55063',
    maxFipsMinOtcLtdAmount: 888,
    maxFipsMinGivebackLtdFips: '55039',
    maxFipsMinOtcLtdCountyName: 'La Crosse',
    maxFipsMinGivebackLtdAmount: 15,
    maxFipsMinGivebackLtdCountyName: 'Fond du Lac',
  },
  {
    code: 'WV',
    name: 'West Virginia',
    maxOtc: 2400,
    minOtc: 75,
    top5Otc: 900,
    medicaid: 'West Virginia Medicaid',
    minOtcLtd: 780,
    maxGiveback: 164.9,
    minGiveback: 1.2,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '54037',
    maxFipsMinOtcLtdAmount: 780,
    maxFipsMinGivebackLtdFips: '21135',
    maxFipsMinOtcLtdCountyName: 'Jefferson',
    maxFipsMinGivebackLtdAmount: 50,
    maxFipsMinGivebackLtdCountyName: 'Lewis',
  },
  {
    code: 'WY',
    name: 'Wyoming',
    maxOtc: 1344,
    minOtc: 100,
    top5Otc: 300,
    medicaid: 'EqualityCare',
    minOtcLtd: 500,
    maxGiveback: 164.9,
    minGiveback: 10,
    minGivebackLtd: 50,
    maxFipsMinOtcLtdFips: '56035',
    maxFipsMinOtcLtdAmount: 1344,
    maxFipsMinGivebackLtdFips: '16005',
    maxFipsMinOtcLtdCountyName: 'Sublette',
    maxFipsMinGivebackLtdAmount: 100,
    maxFipsMinGivebackLtdCountyName: 'Bannock',
  },
]

export const getCurrentState = async () => {
  return new Promise(async (resolve) => {
    const queryState = query.state?.toUpperCase()
    if (queryState && states.map((state) => state.code).includes(queryState)) {
      resolve(queryState)
      return
    }
    await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
    const ipLookupStateCode = ip.lookup?.stateCode
    if (ipLookupStateCode) {
      resolve(ipLookupStateCode)
    }
  })
}
