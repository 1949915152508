<script setup lang="ts">
const { value: groupHealth } = useField('groupHealth')
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red-500 text-center">
    Take this short quiz to see if you qualify
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Do you get health insurance through your job?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="groupHealth = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="groupHealth = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="groupHealth = null"
    >
      Skip
    </Button>
  </div>
</template>
