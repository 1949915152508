<script setup lang="ts">
const route = useRoute()

const benefits = computed(() => {
  return route.meta.benefits as string[] | undefined
})

const open = ref(true)
</script>

<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>

    <div class="border border-gray-300 bg-white">
      <div
        class="p-4 border-b border-gray-300 flex items-center justify-between"
        @click="open = !open"
      >
        <div>
          <div class="text-lg font-medium"> Important Disclaimers </div>
          <div class="text-gray-600"> (scroll within this box to view) </div>
        </div>
        <Icon
          i-heroicons-outline:chevron-down
          :class="[
            open && 'rotate-180',
            'text-gray-500 transition-transform duration-300',
          ]"
        />
      </div>
      <div
        :class="[
          open ? 'h-64' : 'h-0',
          'overflow-auto transition-all duration-300',
        ]"
      >
        <div class="p-4 text-gray-600">
          Participating sales agencies represent Medicare Advantage [HMO, HMO
          SNP, PPO, PPO SNP and PFFS] organizations and Medicare-approved Part D
          sponsors that are contracted with Medicare. Enrollment depends on the
          plan's contract renewal with Medicare.
          <br />
          <br />

          We do not offer every plan available in your area. Currently we
          represent over 22 organizations which offer 38 products in your area.
          Please contact Medicare.gov, 1-800-MEDICARE, or your local State
          Health Insurance Program (SHIP) to get information on all of your
          options.
          <br />
          <br />

          Not affiliated with or endorsed by any government agency. This is a
          solicitation of insurance. There is absolutely no obligation to
          purchase anything. At {{ brand.url }} you will talk with a licensed
          insurance agent in your state. Enrollment in a plan may be limited to
          certain times of the year unless you qualify for a special enrollment
          period or you are in your Medicare Initial Election Period.
          <br />
          <br />

          <template v-if="benefits && benefits.length > 1">
            Not all plans offer all of these benefits. Benefits may vary by
            carrier and location. Limitations and exclusions may apply.
            <br />
            <br />
          </template>

          <FooterBenefits />

          By using this site, you acknowledge that you have read and agree to
          the
          <Link to="/tos">Terms of Service</Link>
          and
          <Link to="/privacy">Privacy Policy</Link>.

          <div class="sm:hidden">
            <br />
            <div class="flex items-center space-x-6 my-2">
              <img src="../assets/bbb-badge.svg" width="97" height="37" />
              <img src="../assets/comodo-badge.svg" width="107" height="34" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-gray-600">
      Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All rights
      reserved | 8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069

      <template v-if="smid">
        <br />
        <br />
        {{ smid }}
      </template>
    </div>
  </div>
</template>
