import { StorageSerializers } from '@vueuse/core'

interface Experiments {
  [key: string]: {
    id: string
    enabled: boolean
    sessionScoped?: boolean
    variants: {
      [key: string]: number
    }
  }
}

const defineExperiments = <T extends Experiments>(experiments: T) => experiments

const experiments = defineExperiments({
  houseAd: {
    id: '2022-11-21-house-ad',
    enabled: true,
    variants: {
      show: 50,
      hide: 50,
    },
  },
  carrierTyp: {
    id: '2022-12-01-carrier-typ',
    enabled: false,
    variants: {
      carrierTyp: 50,
      originalTyp: 50,
    },
  },
  helplineFacebook: {
    id: 'helpline-facebook-2023-03-22',
    enabled: true,
    variants: {
      original: 50,
      helpline: 50,
    },
  },
  groceryAllowanceFormVsCtc: {
    id: 'grocery-allowance-form-vs-ctc-2023-05-15',
    enabled: true,
    variants: {
      form: 50,
      ctc: 50,
    },
  },
  go4BenefitsCopy: {
    id: 'go-4-benefits-copy-2023-06-27',
    enabled: false,
    variants: {
      spendingAllowances3300: 50,
      groceryAllowance900: 50,
    },
  },
  testimonials: {
    id: 'testimonials-2023-07-05',
    enabled: true,
    variants: {
      show: 0,
      hide: 1,
    },
  },
  buyback2024Image: {
    id: 'buyback-2024-image-2023-08-30',
    enabled: false,
    variants: {
      couple: 0,
      olderCouple: 0,
      original: 1,
      coupleSwimming: 0,
      coinJar: 0,
      coinJar2: 0,
      seniorsHandsUp: 0,
    },
  },
  newBuyback2024Image: {
    id: 'new-buyback-2024-image-2024-06-14',
    enabled: true,
    variants: {
      yoga: 1,
      original: 0,
      iceCream: 1,
      happyWoman: 0,
    },
  },
  newHealthyFoodsImage: {
    id: 'new-healthy-foods-image-2023-09-06',
    enabled: true,
    variants: {
      fruits: 0,
      fruitsWithText: 1,
      bananasWithText: 0,
      womanRedCard: 0,
      coupleHoldingCard: 0,
    },
  },
  test: {
    id: 'test-2023-09-28',
    enabled: true,
    variants: {
      a: 1,
      b: 1,
    },
  },
  carrierLogosBelowTheForm: {
    id: 'carrier-logos-below-the-form-2023-10-20',
    enabled: true,
    variants: {
      show: 50,
      hide: 50,
    },
  },
  otcAdDestination: {
    id: 'otc-ad-destination-2023-10-20',
    enabled: true,
    variants: {
      original: 50,
      toBenefits: 50,
    },
  },
  grocery2024Image: {
    id: 'grocery-2024-image-2023-10-26',
    enabled: false,
    variants: {
      original: 50,
      fruitsWithText: 0,
      allowanceCard1: 0,
      allowanceCard2: 0,
      allowanceCard3: 0,
      allowanceCard4: 50,
    },
  },
  otcAdImage: {
    id: 'otc-ad-image-2023-10-31',
    enabled: false,
    variants: {
      original: 50,
      variant: 50,
    },
  },
  myOtcMedicareQuestion: {
    id: 'my-otc-medicare-question-2023-11-27',
    enabled: true,
    variants: {
      medicare: 0,
      original: 100,
      buyback: 0,
    },
  },
  getDentalBenefits: {
    id: 'my-call-4-added-benefits-2024-01-16',
    enabled: true,
    variants: {
      default: 50,
      dental: 50,
    },
  },
  groceryAllowance2Image: {
    id: 'grocery-allowance-2-image-2024-03-36',
    enabled: true,
    variants: {
      default: 50,
      seniors: 50,
    },
  },
  allowanceD5BHeaderImage: {
    id: 'allowance-d5b-header-image-2024-03-22',
    enabled: true,
    variants: {
      default: 0,
      secondaryImage: 100,
    },
  },
  zeroZeroDental: {
    id: 'zero-zero-dental-2024-04-04',
    enabled: true,
    variants: {
      variant1: 17,
      variant2: 17,
      variant3: 17,
      variant4: 17,
      variant5: 16,
      variant6: 16,
    },
  },
  zeroZeroDentalText: {
    id: 'zero-zero-dental-text-2024-04-04',
    enabled: true,
    variants: {
      variant1: 17,
      variant2: 17,
      variant3: 17,
      variant4: 17,
      variant5: 16,
      variant6: 16,
    },
  },
  allowancePlusBridgeMoreQuestions: {
    id: 'allowance-plus-bridge-more-questions-2024-04-08',
    enabled: false,
    variants: {
      original: 75,
      moreQuestions: 25,
    },
  },
  medicarePremiumReduction2096Text: {
    id: 'medicare-premium-reduction-2096-text-2024-04-10',
    enabled: true,
    variants: {
      original: 50,
      variant: 50,
    },
  },
  shop4DsnpOTC2024Headline: {
    id: 'shop-4-dsnp-otc-headline-2024-04-10',
    enabled: true,
    variants: {
      compare: 20,
      medicareAdvantageDsnp: 20,
      medicareVariableMinAmount: 20,
      variableMinAmountGroceryAllowance: 20,
      affordableMedicare: 20,
    },
  },
  shop4DsnpOTC2024Description: {
    id: 'shop-4-dsnp-otc-description-2024-04-10',
    enabled: true,
    variants: {
      shopMedicareCommon: 9,
      shopMedicareApproved: 9,
      variableMinAmount: 9,
      flexibleAlloance: 9,
      prePaidCard: 8,
      lookingForFlexible: 8,
      variableMinAmountFlexible: 8,
      variableMinAmountFood: 8,
      youMayBeElegible: 8,
      countPlansInState: 8,
      countDsnpPlans: 8,
      somePlansProvide: 8,
    },
  },
  lowAsZeroDentalPremiums: {
    id: 'low-as-zero-dental-premiums-image-2024-04-11',
    enabled: true,
    variants: {
      variant1: 17,
      variant2: 17,
      variant3: 16,
      variant4: 16,
      variant5: 16,
      variant6: 16,
    },
  },
  lowAsZeroDentalDescription: {
    id: 'low-as-zero-dental-premiums-description-2024-04-11',
    enabled: true,
    variants: {
      spendLess: 20,
      zeroOrLow: 20,
      somePlansFlexible: 20,
      coverageForRoutine: 20,
      getDentures: 20,
    },
  },
  lowAsZeroDentalPremiumsCallOption: {
    id: 'low-as-zero-dental-premiums-call-option-2024-04-11',
    enabled: true,
    variants: {
      option1: 50,
      option2: 50,
    },
  },
  additionalBenefitsOtc2024Headline: {
    id: 'additional-benefits-otc-2024-headline-2024-04-15',
    enabled: true,
    variants: {
      someMedicareBeneficiaries: 25,
      stateMedicareAdvantagePlus: 25,
      clickfor2024Medicare: 25,
      youMayQualify: 25,
    },
  },
  additionalBenefitsOtc2024Image: {
    id: 'additional-benefits-otc-2024-iamge-2024-04-15',
    enabled: true,
    variants: {
      allowanceCard: 34,
      allowanceCard3: 33,
      groceryHaul: 33,
    },
  },
  medicarePremiumReduction2096Headline: {
    id: 'medicare-premium-reduction-2096-headline-2024-04-15',
    enabled: true,
    variants: {
      original: 50,
      variant: 50,
    },
  },
  medicarePremiumReduction2096Image: {
    id: 'medicare-premium-reduction-2096-image-2024-04-15',
    enabled: true,
    variants: {
      variant1: 20,
      variant2: 20,
      variant3: 20,
      variant4: 20,
      variant5: 20,
    },
  },
  loConsigasImage: {
    id: 'lo-consigas-image-2024-04-22',
    enabled: true,
    sessionScoped: true,
    variants: {
      original: 1,
      fruitsBehind: 1,
      whiteHairWoman: 1,
    },
  },
  medicarePremiumReductionListingImage: {
    id: 'medicare-premium-reduction-listing-2096-2024-05-02',
    enabled: true,
    sessionScoped: true,
    variants: {
      card: 50,
      womanCheck: 50,
    },
  },
  chatScript: {
    id: 'chat-script-test-2024-06-18',
    enabled: true,
    variants: {
      variantA: 50,
      variantB: 50,
    },
  },
  postCallOnGrocery2024: {
    id: 'post-call-on-grocery-2024-2024-07-29',
    enabled: true,
    variants: {
      shouldRedirect: 25,
      stayInPage: 75,
    },
  },
  grocery2024GoogleDemandCheck: {
    id: 'grocery-2024-google-demand-check-2024-08-22',
    enabled: true,
    variants: {
      shouldCheck: 10,
      shouldNotCheck: 90,
    },
  },
  additionalBenefitsOtcHeadline: {
    id: 'additional-benefits-otc-headline-2024-10-15',
    enabled: true,
    variants: {
      someMedicareBeneficiaries: 25,
      stateMedicareAdvantagePlus: 25,
      medicareAdvantagePlans: 25,
      clickFor2025: 25,
    },
  },
  additionalBenefitsOtcImage: {
    id: 'additional-benefits-otc-image-2024-10-15',
    enabled: true,
    variants: {
      allowanceCard: 34,
      allowanceCard3: 33,
      groceryHaul: 33,
    },
  },
  getYoursX4cArrowVersion: {
    id: 'get-yours-x4c-arrow-2024-10-28',
    enabled: false,
    variants: {
      newVersion: 20,
      default: 80,
    },
  },
  houseAdsSpanish: {
    id: 'ads-es-house-ads-2024-11-06',
    enabled: true,
    variants: {
      top: 50,
      bottom: 50,
    },
  },
  houseAds: {
    id: 'ads-house-ads-2024-11-11',
    enabled: true,
    variants: {
      top: 0,
      off: 50,
      bottom: 50,
    },
  },
  highlightAdsCard: {
    id: 'highlight-ads-card-2024-11-11',
    enabled: false,
    variants: {
      show: 50,
      default: 50,
    },
  },
  groceryAllowanceR2Redirect: {
    id: 'grocery-allowance-r2-2024-11-21',
    enabled: true,
    variants: {
      stay: 50,
      redirect: 50,
    },
  },
  getYourCallNow: {
    id: 'get-your-call-now-2024-11-21',
    enabled: true,
    variants: {
      default: 0,
      black: 100,
    },
  },
} as const)

interface EnteredExperiment {
  id: string
  name: string
  variant: string
  sessionId?: string | null
}

const enteredExperiments = useLocalStorage<EnteredExperiment[]>(
  'enteredExperiments',
  []
)

type Exp =
  | null
  | {
      [ExperimentName in keyof typeof experiments]?: {
        [VariantName in keyof typeof experiments[ExperimentName]['variants']]?: true
      }
    }

export const exp = computed<Exp>(() => {
  const runningEnteredExperiments = enteredExperiments.value
    .filter((experiment) => _.some(experiments, ['id', experiment.id]))
    .filter((experiment) => {
      return !experiment.sessionId || experiment.sessionId === getSession().id
    })
  return runningEnteredExperiments.length
    ? runningEnteredExperiments.reduce((acc: any, experiment) => {
        acc[experiment.name] = {
          [experiment.variant]: true,
        }
        return acc
      }, {})
    : null
})

export const enterExperiment = (name: keyof typeof experiments) => {
  if (!import.meta.env.SSR && !exp.value?.[name]) {
    const { id, variants, enabled, sessionScoped } = experiments[
      name
    ] as Experiments['key']

    if (!enabled) return

    const variant = _.sample(
      _.flatMap(variants, (weight, name) => _.times(weight, () => name))
    )!

    const enteredExperiment: EnteredExperiment = { id, name, variant }

    if (sessionScoped) {
      enteredExperiment.sessionId = getSession().id
    }

    enteredExperiments.value = [...enteredExperiments.value, enteredExperiment]

    analytics.track('enteredExperiment', enteredExperiment)
  }
}

export const forceEnterExperiment = (id: string, variant: string) => {
  if (!import.meta.env.SSR) {
    const experiment: any = _.find(experiments, (experiment) => {
      return experiment.id === id
    })

    if (experiment && variant in experiment.variants) {
      const enteredExperiment: EnteredExperiment = {
        id,
        name: _.findKey(experiments, (experiment) => experiment.id === id)!,
        variant,
      }

      if ('sessionScoped' in experiment && experiment.sessionScoped) {
        enteredExperiment.sessionId = getSession().id
      }

      enteredExperiments.value = [
        ...enteredExperiments.value.filter(
          (experiment) => experiment.id !== id
        ),
        enteredExperiment,
      ]
    }
  }
}

// TODO: remove when all running experiments started after 2022-11-23

const expData = useLocalStorage<EnteredExperiment | null>('expData', null, {
  serializer: StorageSerializers.object,
})

onInit(({ isClient }) => {
  if (isClient) {
    if (
      expData.value &&
      !enteredExperiments.value.some(
        (experiment) => experiment.id === expData.value!.id
      )
    ) {
      enteredExperiments.value = [...enteredExperiments.value, expData.value]
      expData.value = null
    }
  }
})

export function getCurentValidExperiment(variable: any): string | undefined {
  for (const key in variable) {
    if (variable[key] === true) {
      return key
    }
  }
  return undefined
}
