<script setup lang="ts">
import imgBuybackCouple from '../assets/buyback-couple.png'
import { ArticleAuthor, ArticleType } from '~/modules/articles'
const articleAuthor: ArticleAuthor = {
  name: 'Emily',
  avatarUrl: '',
  role: 'AI Assistant',
}

const defaulArticles = [
  {
    id: 'use-medicare-while-working',
    title: 'Can I Use Medicare and an Employer-Based Insurance Plan?',
    content: 'Written by Maggie Aime, MSN, RN',
    image: imgBuybackCouple,
    articleAuthor: [articleAuthor],
    expanded: false,
  },
  {
    id: 'how-much-medicare-part-d-cost',
    title: 'How Much Does Medicare Part D Cost?',
    content: 'Written by Sheila Miller Edwards, PhD',
    image: imgBuybackCouple,
    articleAuthor: [articleAuthor],
    expanded: false,
  },
  {
    id: 'does-medicare-cover-chiropractic-care',
    title: 'Does Medicare Cover Chiropractic Care?',
    content: 'Written by Lisa Martin',
    image: imgBuybackCouple,
    articleAuthor: [articleAuthor],
    expanded: false,
  },
]

const props = defineProps<{
  articles?: ArticleType[]
}>()

const relatedArticles = computed(() => {
  return props.articles && props.articles.length > 0
    ? props.articles
    : defaulArticles
})
</script>

<template>
  <div class="space-y-6">
    <div class="space-y-1">
      <div class="text-2xl font-medium"> Related articles </div>
      <div>
        <ArticleLink to="/go-4-benefits"> See more about Medicare </ArticleLink>
      </div>
    </div>

    <div class="space-y-4">
      <RouterLink
        v-for="article in relatedArticles"
        :key="article.id"
        to="/go-4-benefits"
        class="flex items-center gap-4 p-4 bg-white rounded-md shadow ring-1 ring-gray-200"
      >
        <img :src="article.image" class="w-20 h-20 object-cover rounded-md" />
        <div class="space-y-1">
          <div class="font-medium"> {{ article.title }} </div>
          <div class="text-sm text-gray-500"> {{ article.content }} </div>
        </div>
      </RouterLink>
    </div>
  </div>
</template>
