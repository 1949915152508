<script setup lang="ts">
const emit = defineEmits(['close'])

const answer = ref<'thumbsUp' | 'thumbsDown' | null>(null)

whenever(answer, () => {
  analytics.track('postCallReviewAnswer', {
    answer: answer.value,
  })
})

onMounted(() => {
  analytics.track('postCallReviewShown')
})
</script>

<template>
  <div class="p-8">
    <div
      class="absolute top-3 right-3 p-1 -m-1 rounded-full cursor-pointer hover:bg-gray-100 text-gray-400 hover:text-gray-500"
      @click="emit('close')"
    >
      <Icon i-heroicons-outline:x />
    </div>

    <template v-if="!answer">
      <div class="text-3xl mb-8 text-center"> How was your call? </div>
      <div class="flex items-center justify-center gap-4">
        <div class="bg-gray-200 p-4 rounded-md" @click="answer = 'thumbsUp'">
          <Icon i-heroicons-outline:thumb-up class="h-8 w-8" />
        </div>
        <div class="bg-gray-200 p-4 rounded-md" @click="answer = 'thumbsDown'">
          <Icon i-heroicons-outline:thumb-down class="h-8 w-8" />
        </div>
      </div>
      <div class="h-px bg-gray-200 my-8"></div>
      <div class="flex justify-center">
        <ClickToCall
          no-post-call-review
          :props="{ ctcLocation: 'post-call-review-modal' }"
          v-slot="{ handleClick }"
        >
          <Button variant="darkBlue" :to="phone.uri" @click="handleClick">
            <Icon i-heroicons-solid:phone class="w-8 h-8 mr-4" />
            <span> Call back </span>
          </Button>
        </ClickToCall>
      </div>
    </template>
    <template v-else>
      <div class="text-3xl text-center"> Thank You! </div>
    </template>
  </div>
</template>
