<script setup lang="ts">
defineProps<{
  partBPremium?: string
}>()

const hostname = ref<string>()

onMounted(() => {
  hostname.value = window.location.hostname
})
</script>

<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      {{ brand.url }} is not connected with or endorsed by the United States
      government or the Federal Medicare program. Not all products are available
      in all states. Products are available in the following states: AK, AL, AR,
      AZ, CA, CO, CT, DC, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME,
      MI, MN, MO, MS, MT, NC, ND, NE, NH, NJ, NV, OH, OK, OR, PA, RI, SC, SD,
      TN, TX, UT, VA, VT, WI, WV and WY. There is absolutely no obligation to
      purchase anything. At {{ brand.name }}, you will talk with a licensed
      insurance agent in your state.
      <br />
      <br />
      <template v-if="brand.id === 'm10' && $route.path === '/'">
        Participating sales agencies represent Medicare Advantage [HMO, PPO,
        PFFS] and/or
        <img
          src="../assets/pdp.png"
          width="159"
          height="24"
          class="inline-block align-bottom"
          alt="PDP"
        />
        organizations that are contracted with Medicare. Enrollment depends on
        the plan's contract renewal.
      </template>
      <template v-else>
        {{ brand.url }} is a licensed and certified representative of Medicare
        Advantage [HMO, HMO SNP, PPO, PPO SNP and PFFS] organizations [and
        stand-alone PDP
        <img
          src="../assets/pdp-lower.png"
          width="162"
          height="24"
          class="inline-block align-bottom"
          alt="PDP"
        />] that have a Medicare contract. Enrollment in any plan depends on
        contract renewal.
      </template>
      Not all plans offer all of these benefits. Benefits may vary by carrier
      and location. Limitations and exclusions may apply. Calling this number
      <ClickToCall v-slot="{ handleClick }: any">
        <a :href="phone.uri" @click="handleClick">
          {{ phone.formatted }}
        </a>
      </ClickToCall>
      TTY 711 24/7 will connect you to a licensed insurance agent.
      <br />
      <br />
      By using this site, you acknowledge that you have read and agree to the
      <Link to="/tos">Terms of Service</Link>
      and
      <Link to="/privacy">Privacy Policy</Link>.
      <br />
      <br />
      <template v-if="$route.path.startsWith('/ggb-form')">
        *You may only be eligible to enroll in a Medicare Advantage Plan if you
        meet certain qualifying events, such as new to Medicare, moving, or
        losing coverage. Other circumstances may apply. These qualifying events
        do not automatically qualify you for a plan with any benefits mentioned.
        Benefits, features, amounts and/or devices vary by plan, area and
        eligibility. Limitations and exclusions apply. Some benefits mentioned
        on this website may be part of a special supplemental program for the
        chronically ill. Not all members qualify. Certain benefits mentioned may
        require authorization by a care manager for some members.
        <br />
        <br />
      </template>
      We do not offer every plan available in your area. Any information we
      provide is limited to those plans we do offer in your area. Please contact
      Medicare.gov or 1-800-MEDICARE to get information on all of your options.
      TTY users should call 1-877-486-2048 24 hours a day/7 days a week.
      <br />
      <br />
      <FooterBenefits />
      This is a solicitation of insurance. Enrollment in a plan may be limited
      to certain times of the year unless you qualify for a special enrollment
      period or you are in your Medicare Initial Election Period.
      <br />
      <br />
      <div class="sm:hidden">
        <div class="flex items-center space-x-6 my-2">
          <img src="../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../assets/comodo-badge.svg" width="107" height="34" />
        </div>
        <br />
      </div>
      Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All rights
      reserved | 8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069
      <template v-if="smid">
        <br />
        <br />
        {{
          hostname === 'medicare10.com' && $route.path === '/'
            ? 'MULTI-PLAN_EH2022WEB02.03v1_M'
            : smid
        }}
      </template>
    </div>
  </div>
</template>
