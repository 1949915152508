<script setup lang="ts">
const props = defineProps<{
  white?: boolean
  size?: 'sm' | 'md' | 'lg'
}>()

const computedSize = computed(() => {
  let size = props.size || 'md'
  switch (size) {
    default:
      return 'h-6 w-6'
    case 'lg':
      return 'h-12 w-12'
    case 'sm':
      return 'h-3 w-3'
  }
})
</script>

<template>
  <span
    :class="[
      white
        ? 'border-t-white border-r-white'
        : 'border-t-darkBlue border-r-darkBlue',
      'animate-spin block rounded-full border-4 border-b-transparent border-l-transparent',
      computedSize,
    ]"
  >
  </span>
</template>
