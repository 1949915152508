<script setup lang="ts">
useHead({
  title: 'Thank you - Your Medicare10 Quote Was Successful',
})

analytics.page({
  name: 'Call Only TYP',
  category: 'TYP',
  product: 'medicare',
})
</script>

<template>
  <Layout>
    <!-- hero -->
    <div class="bg-darkBlue text-white">
      <div class="container py-16 flex flex-col items-center lg:flex-row">
        <img
          src="../../assets/confirmed-bro.svg"
          class="w-[460px] -mt-14 lg:-mb-14 lg:mr-8"
        />
        <div class="flex-1 text-center lg:text-left">
          <h1 class="text-5xl sm:text-6xl mb-12">
            Your agent is on the line waiting for your call
          </h1>
          <ClickToCall v-slot="{ handleClick }: any">
            <Button :to="phone.uri" @click="handleClick"> Call Now </Button>
          </ClickToCall>
        </div>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "ayb"],
    "smid": "MULTIPLAN_EHWEB0819v1_M",
    "robots": "noindex"
  }
}
</route>
