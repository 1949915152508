<template>
  <div class="space-y-2">
    <div class="flex items-center gap-4">
      <div class="text-xl"> Was this page helpful? </div>
      <div class="flex gap-1">
        <div class="p-3 rounded-full bg-amber-200">
          <Icon i-heroicons-solid:thumb-up class="text-amber-800" />
        </div>
        <div class="p-3 rounded-full bg-amber-200">
          <Icon i-heroicons-solid:thumb-down class="text-amber-800" />
        </div>
      </div>
    </div>
    <ArticleSocialLinks />
  </div>
</template>
