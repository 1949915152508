<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `My Affordable Healthcare - ${brand.name}`,
})

const date = ref()
const dateSmall = ref()

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
  dateSmall.value = DateTime.now().toFormat('MM-dd-y')
})

const card = ref(null)

const { height } = useElementSize(card)

const name = computed(() => {
  const firstname = query.firstname?.trim() || ''
  const lastname = query.lastname?.trim() || ''
  const fullName = `${firstname} ${lastname}`.trim()
  return fullName.length > 17 && firstname && lastname
    ? `${firstname.charAt(0)} ${lastname}`
    : fullName
})
</script>

<template>
  <Layout hideHeader footer="aca" class="container">
    <div class="max-w-3xl mx-auto space-y-6 pt-4 pb-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <div class="text-xl leading-6.5 sm:text-3xl font-bold text-center">
        Get your <span class="text-red">$1400 Healthcare Subsidy</span>. Secure
        Health Coverage for <span class="text-red">$0 Monthly</span>!
      </div>

      <div class="text-lg sm:text-2xl text-center">
        Residents of
        <span class="text-red">{{ ip.lookup?.stateName || 'America' }}</span
        >: Claim up to $1,400 in Premium Tax Credits for Health Insurance
        <span class="text-red">today</span>, courtesy of the Affordable Care
        Act!
      </div>

      <div ref="card" class="aspect-[500/240] max-w-[500px] relative mx-auto">
        <img
          src="../../assets/affordable-care-act-check.png"
          class="w-full h-full"
        />
        <div
          class="absolute left-[19%] bottom-[55%] w-[53%] text-black uppercase font-sans font-normal"
          :style="{
            fontSize: `${height * 0.1}px`,
            lineHeight: `${height * 0.15}px`,
          }"
        >
          <Typewriter
            v-if="name"
            :text="name"
            class="truncate"
            cursor=""
            :speed="75"
          />
          <div v-else class="truncate"> Emily Taylor </div>
        </div>
        <div
          class="absolute left-[61%] bottom-[75%] w-[22%] text-black uppercase font-sans font-normal text-center"
          :style="{
            fontSize: `${height * 0.08}px`,
            lineHeight: `${height * 0.12}px`,
          }"
        >
          <div class="truncate"> {{ dateSmall }} </div>
        </div>
      </div>

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="myAffordableHealthcare"
            class="!max-w-3xl py-6 px-4 sm:px-6"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'groupHealth' },
              { id: 'income' },
              { id: 'medicareOrMedicaid' },
              {
                id: 'sorry',
                if: values?.haveMedicareOrMedicaid === true,
              },
              { id: 'us' },
              { id: 'loading' },
              { id: 'call', completed: true },
            ]"
            product="aca"
          />
        </UseForm>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "ayb"],
    "robots": "noindex"
  }
}
</route>
