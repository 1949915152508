<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

const props = defineProps<{
  head?: any
  page?: any
}>()

useHead(
  props.head || {
    title: `${brand.name} - Get Health Insurance for $9/mo`,
  }
)

analytics.page(
  props.page || {
    name: 'ACA LP 1.0',
    category: 'LP',
    product: 'aca',
  }
)

const breakpoints = useBreakpoints(breakpointsTailwind)

const showExitModal = ref(false)

watchOnce(usePageLeave(), () => {
  if (breakpoints.greater('sm').value) {
    showExitModal.value = true
  }
})
</script>

<template>
  <Layout header="simple" footer="aca">
    <!-- hero -->
    <div class="bg-gradient-to-r from-[#446df6] to-[#08a4db] text-white">
      <div class="container py-16 lg:flex">
        <div class="lg:flex-1 lg:self-center lg:max-w-lg lg:mr-auto">
          <h1 class="text-4xl sm:text-5xl !leading-snug mb-12 font-bold">
            <slot name="title">
              Health Insurance for
              <span class="color-gray-300 line-through decoration-red"
                >$739/mo</span
              >
              $9/mo
            </slot>
          </h1>
          <LandingPageAcaForm />
        </div>
        <img
          src="../assets/landing-page-aca-hero.png"
          class="w-[460px] -mb-16 mt-16 mx-auto lg:mt-0 lg:ml-16 lg:mr-0 lg:self-end"
        />
      </div>
    </div>

    <!-- states -->
    <div class="hidden lg:block container py-16 space-y-12">
      <div class="max-w-3xl mx-auto text-center space-y-6">
        <h2 class="text-6xl">
          Click Your <Underline>State</Underline> Below
        </h2>
        <div class="text-2xl text-gray-500">
          We'll check if you qualify for
          <span class="text-gray-900 font-medium"> plans as low as $9/mo </span>
          in minutes
        </div>
      </div>
      <div class="relative max-w-5xl mx-auto py-10">
        <img
          src="../assets/us-map.svg"
          class="absolute inset-0 w-full h-full object-contain select-none"
        />
        <div
          class="relative grid grid-rows-[repeat(10,auto)] grid-cols-5 grid-flow-col gap-y-2"
        >
          <div v-for="state in states" :key="state.code">
            <RouterLink
              class="text-xl text-blue-900 hover:text-red"
              :to="`/affordable-healthcare-3/?state=${state.code}`"
            >
              {{ state.name }}
            </RouterLink>
          </div>
        </div>
      </div>
    </div>

    <!-- states mobile -->
    <div class="lg:hidden container py-16 space-y-12">
      <div class="max-w-3xl mx-auto text-center space-y-6">
        <h2 class="text-4xl sm:text-5xl md:text-6xl">
          Select Your <Underline>State</Underline> Below
        </h2>
        <div class="text-2xl text-gray-500">
          We'll check if you qualify for
          <span class="text-gray-900 font-medium"> plans as low as $9/mo </span>
          in minutes
        </div>
      </div>
      <LandingPageAcaFormState />
    </div>

    <!-- phone -->
    <div class="bg-gradient-to-r from-[#446df6] to-[#08a4db] text-white">
      <div class="container flex flex-col-reverse lg:flex-row xl:max-w-6xl">
        <img
          src="../assets/landing-page-aca-woman-phone.png"
          class="w-80 lg:w-96 self-center lg:self-end lg:mr-16"
        />
        <div
          class="flex-1 self-center mt-16 mb-8 lg:mb-16 p-4 bg-[#366eff] rounded-lg [box-shadow:-12px_12px_#8ed2e9]"
        >
          <div class="text-3xl sm:text-4xl"> Shop by Phone </div>
          <div class="text-4xl sm:text-5xl !leading-normal font-bold">
            {{ phone.formatted }}
          </div>
          <div class="text-3xl sm:text-4xl text-[#8ed2e9]">
            Find a plan in minutes
          </div>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div class="container py-16 space-y-12">
      <h2 class="text-4xl sm:text-5xl md:text-6xl text-center">
        Frequently Asked <Underline>Questions</Underline>
      </h2>
      <div class="grid lg:grid-cols-2 gap-4">
        <div>
          <AccordionPanel
            title="What type of health insurance options fit my budget?"
          >
            It's important to find a health plan that fits not only your medical
            needs but also your budget. Health plans come in all shapes and
            sizes, so it's important to do your research and work with your
            agent to find the best fit for you.
          </AccordionPanel>
          <AccordionPanel
            title="How is it possible to get health insurance for $9/mo?"
          >
            There is only one reason it's possible to get good health insurance
            for this cheap, generous new government subsidy that many people
            qualify for.
          </AccordionPanel>
          <AccordionPanel title="Do I qualify?">
            You may qualify for large health subsidies if you make less than
            $100k/year. Our tools make it easy to calculate your subsidy and
            find the best plan for you and your family.
          </AccordionPanel>
        </div>
        <div>
          <AccordionPanel title="Affordable Care Act (ACA) Plans">
            The comprehensive healthcare reform law, ACA, or Obamacare, requires
            qualified insurers to provide individual health insurance plans to
            enrollees that meet the minimum essential coverage requirements.
            These plans are designed to make healthcare more affordable and to
            provide coverage to everyone, including those with pre-existing
            conditions.
            <br />
            <br />
            With an ACA health plan, you pay a monthly premium and typically, a
            co-pay when you visit your doctor. When choosing your plan, look at
            the deductible, which is the amount of money you pay before your
            insurance “kicks in,” and your out-of-pocket maximum, which is the
            total amount of money you pay annually before your insurance covers
            you 100%.
            <br />
            <br />
            While all ACA health insurance plans include dental and vision
            coverage for children, they may not include it for adults. If you
            need vision and dental coverage, and your plan doesn't include it,
            you'll have to purchase it separately.
          </AccordionPanel>
          <AccordionPanel title="Short Term Plans">
            Short-term health insurance is a type of insurance that is designed
            to fill gaps in coverage. It is often used by people who are:
            <br />
            <br />
            - Switching jobs and in-between benefits <br />
            - Waiting to be Medicare-eligible <br />
            - Not qualified for an ACA plan, but will have other insurance in
            the near future <br />
            - Transitioning from their parent's health insurance plan to their
            own
            <br />
            <br />
            It is important to keep in mind that short-term medical plans often
            do not have to meet the same guidelines as ACA plans. Because of
            this, many of them do not cover pre-existing conditions, including
            pregnancy.
            <br />
            <br />
            Some plans also have maximum payout amounts and waiting periods for
            coverage to begin. So, when choosing a short-term plan, it is often
            important to completely understand what is and is not covered before
            making a purchase.
          </AccordionPanel>
          <AccordionPanel title="What is the Affordable Care Act?">
            The Affordable Care Act is a law that was passed in 2010 that aims
            to provide affordable health care to all Americans. The law requires
            all Americans to have health insurance and provides subsidies to
            help make insurance more affordable. The law also requires insurers
            to provide coverage for pre-existing conditions and prohibits them
            from charging higher prices to women.
          </AccordionPanel>
          <AccordionPanel
            title="New: What is the 2022 Inflation Reduction Act?"
          >
            In addition to making prescription drugs more affordable, the
            Inflation Reduction Act extends the tax credits rolled out by the
            Afforable Care Act (ACA). These subsidies were previously expanded
            by the American Rescue Plan Act of 2021. The enhanced credits were
            set to expire at the end of 2022, but will now continue through
            2025. This law ensured that subsidies will cover the majority of the
            cost of premiums for enrollees.
            <br />
            <br />
            According to a KFF analysis, the enhanced subsidies ensure that no
            one purchasing an ACA mid-level silver plan spends more than 8.5% of
            their income on health insurance premiums.
          </AccordionPanel>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>

    <!-- quote -->
    <div class="container py-16 space-y-12">
      <div class="max-w-3xl mx-auto text-center space-y-6">
        <h2 class="text-4xl sm:text-5xl md:text-6xl">
          Get a <Underline>FREE</Underline> no obligation quote
        </h2>
        <div class="text-2xl text-gray-500">
          Take the first step towards
          <span class="text-gray-900 font-medium">protecting your health</span>
          with an instant quote.
        </div>
      </div>

      <div class="max-w-2xl mx-auto">
        <LandingPageAcaForm />
      </div>

      <div class="flex items-center justify-center space-x-4">
        <Icon
          i-heroicons-outline:check-circle
          class="hidden sm:block text-green-600"
        />
        <div class="text-xl text-gray-500 text-center sm:text-left">
          We've helped
          <span class="text-gray-900 whitespace-nowrap">200+ people</span> in
          your area!
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>

    <Modal :open="showExitModal" @close="showExitModal = false">
      <ModalExit to="/affordable-healthcare-3" @close="showExitModal = false" />
    </Modal>
  </Layout>
</template>
