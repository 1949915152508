<template>
  <div class="article">
    <slot />
  </div>
</template>

<style>
.article {
  @apply space-y-6 text-gray-700 max-w-xl mx-auto;
}

.article h1 {
  @apply text-3xl font-medium text-gray-900;
}

.article h2 {
  @apply text-2xl font-medium text-gray-900;
}

.article h3 {
  @apply text-xl font-medium text-gray-900;
}

.article h4 {
  @apply text-lg font-medium text-gray-900;
}

.article h5 {
  @apply text-gray-900;
}

.article ul {
  @apply list-disc list-outside space-y-2 ml-4;
}

.article th {
  @apply p-3 text-left font-medium border border-gray-300 bg-gray-100 text-gray-900 align-top;
}

.article td {
  @apply p-3 border border-gray-300 bg-white align-top;
}

.article strong {
  @apply font-semibold text-gray-900;
}
</style>
