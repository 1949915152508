<script setup lang="ts">
defineProps<{
  partBPremium?: string
  ehealthTracking?: string
}>()

const hostname = ref<string>()

onMounted(() => {
  hostname.value = window.location.hostname
})
</script>

<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      {{ brand.url }} (powered by Easy Health Insurance Services LLC) is not
      connected with or endorsed by the United States government or the Federal
      Medicare program. There is absolutely no obligation to purchase anything.
      We do not offer every plan available in your area. Currently we represent
      64 organizations which offer 3,763 products in your area. Please contact
      Medicare.gov, 1-800-MEDICARE, or your local State Health Insurance Program
      (SHIP) to get information on all your options.
      <br />
      <br />
      Not all plans offer all of these benefits. Benefits may vary by carrier
      and location. Limitations and exclusions may apply.
      <br />
      <br />
      Participating sales agencies represent Medicare Advantage HMO, PPO and
      PFFS organizations and PDP that are contracted with Medicare. Enrollment
      depends on the plan's contract renewal with Medicare. Not affiliated with
      or endorsed by any government agency.
      <br />
      <br />
      Callers will be directed to a licensed insurance agent. This is a
      solicitation of insurance. Enrollment in a plan may be limited to certain
      times of the year unless you qualify for a special enrollment period or
      you are in your Medicare Initial Election Period.
      <br />
      <br />
      To send a complaint to an insurance carrier, call the Plan or the number
      on your member ID card. To send a complaint to Medicare, call
      1-800-MEDICARE (TTY users should call 1-877-486-2048), 24 hours a day/7
      days a week). If your complaint involves a broker or agent, be sure to
      include the name of the person when filing your grievance.
      <br />
      <br />
      By using this site, you acknowledge that you have read and agree to the
      <Link to="/tos">Terms of Service</Link> and
      <Link to="/privacy">Privacy Policy</Link>.

      <template v-if="smid">
        <br />
        <br />
        {{ smid }}
      </template>

      <template v-if="ehealthTracking">
        <br />
        <br />
        {{ ehealthTracking }}
      </template>
    </div>
  </div>
</template>
